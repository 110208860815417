import {
  Tabs as ChakraTabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

const Tabs = ({
  index,
  tabs,
  tabsProps,
  tabProps,
  tabListProps,
  tabPanelProps,
  onChange,
  isFitted,
  variant,
  ...rest
}) => {
  return (
    <ChakraTabs
      isLazy
      {...tabsProps}
      index={index}
      onChange={onChange}
      isFitted={isFitted}
      variant={variant}
    >
      <TabList {...tabListProps}>
        {tabs.map((tab) => (
          <Tab key={tab.id} {...tabProps} className={tab.id === 2 && rest.id}>
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab) => (
          <TabPanel key={tab.id} {...tabPanelProps}>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
};

export default Tabs;
