import { isZoomApp, openUrlFromWeb } from "@utils/helpers";
import { openURLFromZoom } from "@sdk/zoom/utils";

const zoomAppUtils =  {
  openUrl: openURLFromZoom,
};

const webUtils =  {
  openUrl: openUrlFromWeb,
}

const useSdkUtils = () => {
  const sdkUtils = isZoomApp ? zoomAppUtils : webUtils;

  return { ...sdkUtils };
}

export default useSdkUtils;
