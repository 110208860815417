import { Box, Flex, SimpleGrid, IconButton, Icon } from "@chakra-ui/react";
import { BiChevronUp } from "react-icons/bi";

const ParticipantsSingleView = ({
  containerRef,
  handleScroll,
  renderParticipantType,
}) => {
  return (
    <Box my="10px" overflow="auto">
      <Flex alignItems="center" justifyContent="center" direction="column">
        <IconButton
          variant="ghostIconButton"
          icon={
            <Icon as={BiChevronUp} color="purple.25" w="1.75em" h="1.75em" />
          }
          _hover={{ opacity: 1 }}
          opacity="0.5"
          onClick={() => handleScroll(-60)}
        />
        <Box
          ref={containerRef}
          height="calc(100vh - 490px)"
          width="90px"
          overflowY="scroll"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <SimpleGrid spacing="3" justifyItems="center" margin="7px">
            {renderParticipantType()}
          </SimpleGrid>
        </Box>
        <IconButton
          variant="ghostIconButton"
          icon={
            <Icon as={BiChevronUp} color="purple.25" w="1.75em" h="1.75em" />
          }
          transform="rotate(-180deg)"
          _hover={{ opacity: 1 }}
          opacity="0.5"
          onClick={() => handleScroll(60)}
        />
      </Flex>
    </Box>
  );
};

export default ParticipantsSingleView;
