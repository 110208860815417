import { useEffect, useState } from "react";
import { useSidebar } from "@hooks";
import { Box, Fade } from "@chakra-ui/react";
import {
  Participants,
  Engagement,
  SidebarProfile,
  Tabs,
  News,
  NotificationCount,
} from "@components";
import { useMeeting, useNotifications } from "@hooks";
import { displayEscore, isGuestWebUser } from "@utils/helpers";
import { SIDEBAR_WIDTH } from "@constants";

const SidebarOpen = ({ participants, guests, hostId, points, coins, eScore, profile }) => {
  const { isMeetingActive } = useMeeting();
  const { notifications } = useNotifications();
  const [showItems, setShowItems] = useState(false);
  const { tabIndex, handleTabIndex, isSidebarOpen } = useSidebar();
  const tabsData = [
    {
      id: 0,
      label: "Neighbors",
      content: (
        <Participants participants={participants} guests={guests} hostId={hostId} isGridView={isSidebarOpen} />
      ),
    },
    {
      id: 1,
      label: <NotificationCount label="News" count={notifications.length} />,
      content: <News />,
    },
    { id: 2, label: "Profile", content: <SidebarProfile /> },
  ];

  useEffect(() => {
    if (isSidebarOpen) {
      let timer = setTimeout(() => setShowItems(true), 300);
      return () => clearTimeout(timer);
    }
  }, [isSidebarOpen]);

  return (
    showItems && (
      <Fade in={isSidebarOpen}>
        <>
          {!isGuestWebUser(profile) ? (
            <Box margin="0.75rem">
              <Engagement
                points={points?.toLocaleString()}
                coins={coins?.toLocaleString()}
                score={displayEscore(isMeetingActive, eScore)}
                inline={false}
              />
            </Box>
          ) : null}
          <Box
            margin="2rem 0.75rem"
            position="relative"
            width={`calc(${SIDEBAR_WIDTH} - 1.5rem)`}
          >
            <Tabs
              tabs={tabsData}
              variant="sidebar"
              index={tabIndex}
              onChange={handleTabIndex}
              isFitted={true}
            />
          </Box>
        </>
      </Fade>
    )
  );
};

export default SidebarOpen;
