import { useSidebar } from "@hooks";
import { Flex, Image, IconButton, Icon } from "@chakra-ui/react";
import { BiChevronLeft } from "react-icons/bi";
import sidebarToggleButtonImage from "@assets/sidebar-curve.svg";
import { UserOnboarding } from "@components";

const SidebarButton = ({
  tooltipCounter,
  incrementTooltipCounter,
  isFirstView,
}) => {
  const { isSidebarOpen, handleSidebarToggle } = useSidebar();

  return (
    <Flex position="relative" width="27px">
      <Image maxW="27px" mt="-350px" src={sidebarToggleButtonImage} />
      <UserOnboarding
        isOpen={isFirstView && tooltipCounter === 2}
        placement="bottom"
        onClick={incrementTooltipCounter}
        title="Explore"
        textContent="Open the sidebar to check stats and news"
        step="2 of 3"
      >
        <IconButton
          variant="ghostIconButton"
          icon={
            <Icon as={BiChevronLeft} color="purple.25" w="1.75em" h="1.75em" />
          }
          _hover={{ opacity: 1 }}
          opacity="0.5"
          position="absolute"
          top="50%"
          margin="-180px 0px 0 -5px"
          width="1.5em"
          height="1.5em"
          transform={isSidebarOpen ? "rotate(180deg)" : "rotate(0deg)"}
          transition="transform 0.3s cubic-bezier(0.820, 0.085, 0.395, 0.895)"
          onClick={(e) => {
            if (isFirstView && tooltipCounter <= 3) {
              return;
            }
            handleSidebarToggle();
          }}
        />
      </UserOnboarding>
    </Flex>
  );
};

export default SidebarButton;
