import { Flex, Image, Text } from "@chakra-ui/react";

const StatIndicator = ({ icon, text, value }) => {
  return (
    <Flex
      alignItems="center"
      justify="space-between"
      borderRadius="8px"
      bgColor="dark.400"
      p="10px"
      mb="2"
    >
      <Flex>
        <Image w="16px" h="16px" src={icon} mr="1" />
        <Text fontSize="12px" color="gray.400">
          {text}
        </Text>
      </Flex>
      <Text fontSize="14px" color="gray.100" fontWeight={700}>
        {value}
      </Text>
    </Flex>
  );
};

export default StatIndicator;
