import { useMemo } from "react";
import { useTour } from "@hooks";
import Joyride from "react-joyride";
import { TourTooltip } from "@components";
import { TOUR_STEPS } from "@constants/tour";

const TourContainer = ({ children }) => {
  const { runTour, stepIndex, handleTourCallback } = useTour();
  const hideArrow = useMemo(() => [3, 4].includes(stepIndex), [stepIndex]);

  return (
    <>
      <Joyride
        continuous
        steps={TOUR_STEPS}
        run={runTour}
        stepIndex={stepIndex}
        callback={handleTourCallback}
        tooltipComponent={TourTooltip}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        spotlightPadding={0}
        floaterProps={{ hideArrow }}
      />
      {children}
    </>
  );
};

export default TourContainer;
