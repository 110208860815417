import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { Navbar } from "@components";

const MeetingContainer = ({ children }) => {
  const location = useLocation();
  const bgColor = location.pathname === "/" ? "purple.900" : "dark.800";

  return (
    <Box bg="purple.900">
      <Navbar />
      <Box
        margin="0 auto"
        width={{ base: "100%", md: "50%" }}
        maxW="425px"
        bg={bgColor}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MeetingContainer;
