import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  runTour: false,
  stepIndex: 0,
};

export const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    updateRunTour: (state, action) => {
      state.runTour = action.payload;
    },
    updateTourStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
  },
});

const { reducer, actions } = tourSlice;

export const { updateRunTour, updateTourStepIndex } = actions;

export default reducer;
