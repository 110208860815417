import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { iconApi } from "@store/api/icon";
import { randomNumber } from "@utils/helpers";

const initialState = {
  data: {
    all: [],
    favs: [],
    trending: [],
  },
  iconsChatBox: [],
  iconsChatBoxCord: {},
  isLoading: true,
};

export const iconSlice = createSlice({
  name: "icons",
  initialState,
  reducers: {
    addIconToChatBox: (state, action) => {
      const { iconId, color } = action.payload;
      const foundIcon = state.data.all.find((item) => item._id === iconId);
      if (foundIcon) {
        const newIconData = {
          url: foundIcon.url,
          left: randomNumber(10, 90),
          top: randomNumber(0, state.iconsChatBoxCord?.height - 20),
          expires: dayjs().add(5, "seconds").format(),
          size: randomNumber(20, 30),
          color: color || "white",
        };
        state.iconsChatBox.push(newIconData);
      }
    },

    removeIconsFromChatBox: (state, action) => {
      const unExpiredIcons = state.iconsChatBox.filter((item) => {
        return item.expires > dayjs().format();
      });
      state.iconsChatBox = unExpiredIcons;
    },
    updateIconViewBoxCord: (state, action) => {
      state.iconsChatBoxCord = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      iconApi.endpoints.getAllIcons.matchFulfilled,
      (state, { payload }) => {
        const { icons, trending, favs } = payload.content || {};
        state.data = { all: icons, trending, favs };
        state.isLoading = false;
      }
    );
    builder.addMatcher(
      iconApi.endpoints.getAllIcons.matchPending,
      (state) => {
        state.isLoading = true;
      }
    );
  },
});

const { reducer, actions } = iconSlice;

export const {
  addIconToChatBox,
  removeIconsFromChatBox,
  updateIconViewBoxCord,
} = actions;

export default reducer;
