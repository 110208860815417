import { Link } from "react-router-dom";
import { Box, Image, Flex, useMediaQuery, Button, Icon, Link as ChakraLink } from "@chakra-ui/react";
import { IoHelpCircle } from "react-icons/io5"
import { HELP_PAGE } from "src/constants";
import townhallLogoOnly from "@assets/townhall-logo-only.svg";
import townhallText from "@assets/townhall_text.svg";

const WebviewNavbar = () => {
  const [isMobile] = useMediaQuery("(max-width: 375px)");

  return (
    <Box
      padding="0.5rem"
      position="static"
      ml={1}
      mr={2}
      height="60px"
    >
      <Flex maxWidth="9xl" justifyContent="space-between" alignItems="center">
        <Link to="/">
          <Flex>
            <Image
              src={townhallLogoOnly}
              alt="Townhall"
              width={isMobile ? 12 : 14}
            />
            <Image
              src={townhallText}
              alt="Townhall"
              display={isMobile && "none"}
            />
          </Flex>
        </Link>
        <ChakraLink href={HELP_PAGE } isExternal>
          <Button
            color="dark.500"
            size={isMobile ? 'xs' : "sm"}
            leftIcon={<Icon as={IoHelpCircle} color="blue.500" fontSize="xl" />}
          >
            Need Help?
          </Button>
        </ChakraLink>
      </Flex>
    </Box>
  );
};

export default WebviewNavbar;
