import { memo } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { routeConfig  } from "@routes";
import { ElementBuilder } from "@routes/ElementBuilder";

const AppRoutes = memo(() => {
  const licenseType = useSelector((state) => state.profile.data?.licenseType);
  const routes = Object.entries(routeConfig)
    .map(([path, config]) => {
      const element = new ElementBuilder(config, licenseType)
        .withOwnContainer()
        .withAuthContainer()
        .withProContainer()
        .build();

      return <Route key={path} path={path} element={element} />
    }
  );

  return <Routes>{routes}</Routes>
});

export { AppRoutes };
