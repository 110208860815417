import { Pro, WebviewContainer } from "@components";

import {
  Login,
  ZoomInstallation,
  Register,
  ConfirmEmail,
  ResetPassword,
  NewPassword,
  CheckEmail,
  VerifyEmail,
  Onboarding,
  Setup,
  AccountView,
  Home,
  MeetingView,
  AgendaView,
  JoinMeeting,
  SelectUserType,
} from "@routes/lazyImports";

export const routeConfig = {
  "/": {
    component: SelectUserType,
    requiresAuthentication: true,
  },
  "/home": {
    component: Home,
    requiresAuthentication: true,
  },
  "/login": {
    component: Login,
  },
  "/zoomapp": {
    component: ZoomInstallation,
  },
  "/register": {
    component: Register,
  },
  "/confirm-email/:email": {
    component: ConfirmEmail,
  },
  "/reset-password": {
    component: ResetPassword,
  },
  "/check-email": {
    component: CheckEmail,
  },
  "/create-password": {
    component: NewPassword,
  },
  "/meeting": {
    component: JoinMeeting,
    container: WebviewContainer,
  },
  "/setup": {
    component: Setup,
    requiresAuthentication: true,
  },
  "/account/*": {
    component: AccountView,
    requiresAuthentication: true,
  },
  "/onboarding": {
    component: Onboarding,
    requiresAuthentication: true,
  },
  "/account/verify": {
    component: VerifyEmail,
    requiresAuthentication: true,
  },
  "/meetingview": {
    component: MeetingView,
    requiresAuthentication: true,
  },
  "/pro": {
    component: Pro,
    requiresAuthentication: true,
    isPro: true,
  },
  "/pro/agendas": {
    component: Pro,
    requiresAuthentication: true,
    isPro: true,
  },
  "/pro/agendas/:agendaId": {
    component: AgendaView,
    requiresAuthentication: true,
    isPro: true,
  },
};
