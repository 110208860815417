import { extendTheme } from "@chakra-ui/react";
import colors from "@utils/theme/colors";
import styles from "@utils/theme/styles";
import breakpoints from "@utils/theme/breakpoints";
import Button from "@utils/theme/components/Button/Button";
import Tabs from "@utils/theme/components/Tabs";
import Heading from "@utils/theme/components/Heading";
import Text from "@utils/theme/components/Text";
import Popover from "@utils/theme/components/Popover";
import Checkbox from "@utils/theme/components/Checkbox";
import Tooltip from "@utils/theme/components/Tooltip";
import Input from "@utils/theme/components/Input";
import Alert from "@utils/theme/components/Alert";
import FormLabel from "@utils/theme/components/FormLabel";
import Select from "@utils/theme/components/Select";
import Badge from "@utils/theme/components/Badge";
import Link from "@utils/theme/components/Link";
import { guestCard, guestCardAlt } from "@utils/theme/layerStyles/guestCard";

const customTheme = extendTheme({
  styles,
  colors: {
    success: colors.green[500],
    error: colors.red[500],
    warning: colors.yellow[500],
    gradient: `linear-gradient(268.62deg, ${colors.purple[200]} -50.64%, ${colors.purple[600]} 73.74%)`,
    ...colors,
  },
  breakpoints,
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
  components: {
    Alert,
    Link,
    Input,
    Button,
    Heading,
    Tabs,
    Text,
    Popover,
    Checkbox,
    Tooltip,
    FormLabel,
    Select,
    Badge,
  },
  layerStyles: {
    guestCard,
    guestCardAlt,
  },
});

export default customTheme;
