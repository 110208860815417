import {
  Box,
  Button,
  Icon,
  Text,
  Divider,
  Flex,
  useDisclosure,
  useClipboard
} from "@chakra-ui/react";
import { Avatar, Modal, SendLink } from "@components";
import { getSharableWebUrl } from "@utils/helpers";
import { FaShareAlt } from "react-icons/fa";
import { useToastNotification } from "@hooks";
import { FiCopy } from "react-icons/fi";

const copiedColors = {
  background:"green.50",
  color: "green.600",
  _hover: {
    background:"green.50",
    color: "green.600",
  }
}

const ProShareMeetingLink = ({ profile, meetingInstance, role }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showErrorToast } = useToastNotification();
  const shareableUrl = getSharableWebUrl(meetingInstance);
  const { onCopy, hasCopied } = useClipboard(shareableUrl);

  const handleInviteParticipants = () => {
    onOpen();
  }

  const handleClose = () => {
    onClose();
  }

  const handleCopyLinkToClipboard = async () => {
    try {
      onCopy();
    } catch (error) {
      showErrorToast('Something wrong happened. We couldn\'t copy the url to the clipboard');
    }
  }

  return (
    <Box
      borderRadius="12px"
      border="1px solid"
      borderColor="gray.200"
      my="2"
      shadow="sm"
    >
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="xs"
        heading="Share Link"
        showCloseButton
      >
        <Box color="gray.600" fontSize="12px" mb={4}>
          <Text fontWeight={600} color="gray.900" fontSize="14px" mb={1}>
            Let your participants join on the web
          </Text>
          <Text mb={2}>Share link below for web and mobile attendees to access Townhall without logging into Zoom!</Text>
          <SendLink
            text={hasCopied ? "Link Copied" : shareableUrl}
            afterIcon={<Icon as={FiCopy} color={hasCopied ? copiedColors.color : null} />}
            handleClick={handleCopyLinkToClipboard}
            styles={hasCopied ? copiedColors : null}
          />
        </Box>
      </Modal>
      <Box p={2} fontSize="12px">
        <Flex alignItems="center">
          <Avatar
            avatar={profile?.avatar}
            size="xs"
            mr={1}
            showBorder={true}
            rounded
            darkIcon
            color="gray.200"
          />
          <Text as ="span" color="gray.600" fontWeight="600">
            {profile?.username || `${profile?.firstName} ${profile?.lastName}`}
          </Text>
          <Text as ="span" color="gray.400" fontWeight="600" mx={1}>•</Text>
          <Text as ="span" color="gray.400" fontWeight="600">{role}</Text>
        </Flex>
      </Box>
      <Divider />
      <Button
        width="full"
        variant="ghost"
        py={2}
        px={4}
        fontSize="12px"
        color="blue.500"
        justifyContent="left"
        borderTopRadius="0px"
        onClick={handleInviteParticipants}
      >
        <Icon as={FaShareAlt} mr={2} />
        <Text as="span">Invite Web Attendees - BETA</Text>
      </Button>
    </Box>
  )
}

export default ProShareMeetingLink;
