import { object, string, number, array, bool } from "yup";

export const signinSchema = {
  initialValues: {
    email: "",
    password: "",
  },
  validation: object({
    email: string()
      .email("Enter a valid email address")
      .required("Email address is required"),
    password: string().required("Password is required"),
  }),
};

export const signupSchema = {
  initialValues: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  },
  validation: object({
    firstName: string()
      .required("First name is required")
      .min(1, "First name must be at least 1 character")
      .max(20, "First name must be at most 20 characters")
      .matches(/^[A-Za-z]+$/, "First name can only contain alphabets"),
    lastName: string()
      .required("Last name is required")
      .min(1, "Last name must be at least 1 character")
      .max(20, "Last name must be at most 20 characters")
      .matches(/^[A-Za-z]+$/, "Last name can only contain alphabets"),
    email: string()
      .email("Enter a valid email address")
      .required("Email address is required"),
    password: string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  }),
};

export const editProfileSchema = {
  validation: object({
    username: string()
      .min(0)
      .max(5, "Username must be 5 characters or less")
      .required("Username is a required field"),
    firstName: string().required("First name is a required field"),
    lastName: string().required("Last name is a required field"),
    organization: string().notRequired(),
    jobTitle: string().notRequired(),
    country: string().notRequired(),
    state: string().notRequired(),
    city: string().notRequired(),
  }),
};

export const resetPasswordSchema = {
  initialValues: {
    email: "",
  },
  validation: object({
    email: string()
      .email("Enter a valid email address")
      .required("Email address is required"),
  }),
};

export const newPasswordSchema = {
  initialValues: {
    password: "",
    confirmPassword: "",
  },
  validation: object({
    password: string().required("Password is required"),
    confirmPassword: string().required("Confirm password is required"),
  }),
};

export const pollSchema = {
  validation: object({
    question: string().required(),
    duration: number().min(1).max(10).required(),
    pollType: string().required(),
    options: array().of(string().required()),
  }),
};

export const quizSchema = {
  validation: object({
    question: string().required(),
    duration: number().min(1).max(10).required(),
    options: array()
      .of(
        object({
          option: string().required(),
          correct: bool().oneOf([true, false]),
        })
      )
      .test({
        name: "checkbox-test",
        message: "You must choose at least one correct option",
        test: (options) => options.some((option) => !!option.correct),
      }),
  }),
};

export const joinMeeting = {
  initialValues: {
    email: "",
    name: "",
  },
  validation: object({
    email: string()
      .email("Enter a valid email address")
      .required("Email address is required"),
    name: string().required("Full name is required"),
  }),
};
