import { debounce } from "@utils/icon";
import { emitIconUsage, emitSendIcon } from "@socket";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addIconToChatBox } from "../store/iconSlice";

const useHandleIconClick = (icons) => {
  const dispatch = useDispatch();

  const broadcastIconClick = useCallback(
    ({ iconId, color, userId, meetingUuid }) => {
      dispatch(addIconToChatBox({ iconId, color }));
      if (meetingUuid) {
        emitSendIcon({
          iconId,
          userId,
          meetingUuid,
          color,
        });
      }
    },
    [dispatch]
  );

  const handleIconClick = useCallback(
    ({ iconId, color, userId, meetingUuid, usageCount, setClickCount }) => {
      if (!setClickCount) {
        throw Error("setClickCount function is required");
      }
      if (meetingUuid) {
        emitIconUsage({
          iconId,
          userId,
          meetingUuid,
          color,
          usageCount,
        });
      }
      setClickCount(1);
    },
    []
  );

  const increaseIconUsage = useCallback(debounce(handleIconClick), []);

  const setIconCategory = useCallback(
    (categoryName) =>
      icons.all.filter((icon) => icon.category.name === categoryName),
    [icons]
  );

  return { broadcastIconClick, increaseIconUsage, setIconCategory };
};

export default useHandleIconClick;
