import { mode } from "@chakra-ui/theme-tools";

const boxShadow = "inset 0px -4px 0px rgba(0, 0, 0, 0.25)";

const activeButtonStyle = {
  transform: "scale(0.95)",
};

const disabledStyles = {
  primary: {
    boxShadow,
    background: "purple.100",
  },
  success: {
    backgroundColor: "green.300",
  },
  error: { backgroundColor: "red.300" },
  active: { transform: "none" },
  svg: { color: "white" },
  ghost: { background: "none" },
};

const variants = {
  primary: {
    // Default
    background: "purple.400",
    color: "white",
    boxShadow,

    // Hover
    _hover: {
      bgGradient: "linear-gradient(to left, purple.500, purple.400)",
    },

    // Active
    _active: {
      ...activeButtonStyle,
    },

    // Disabled
    _disabled: {
      ...disabledStyles.primary,

      _hover: {
        _disabled: {
          ...disabledStyles.primary,
        },
      },

      _active: {
        ...disabledStyles.active,
      },

      "& svg": {
        ...disabledStyles.svg,
      },
    },
  },
  secondary: ({ colorMode }) => ({
    // Default
    background: mode("white", "transparent")({ colorMode }),
    color: mode("dark.600", "gray.25")({ colorMode }),
    borderWidth: "1px",
    borderColor: mode("gray.100", "dark.400")({ colorMode }),
    shadow: mode("sm", "none")({ colorMode }),

    // Hover
    _hover: {
      background: mode("gray.200", "dark.400")({ colorMode }),
    },

    // Active
    _active: {
      ...activeButtonStyle,
    },

    // Disabled
    _disabled: {
      background: mode("gray.200", "dark.400")({ colorMode }),
      color: mode("dark.200", "gray.600")({ colorMode }),

      _hover: {
        _disabled: {
          background: mode("gray.200", "dark.400")({ colorMode }),
          color: mode("dark.200", "gray.600")({ colorMode }),
        },
      },

      _active: {
        ...disabledStyles.active,
      },

      "& svg": {
        ...disabledStyles.svg,
      },
    },
  }),
  success: {
    // Default
    background: "green.500",
    color: "white",
    boxShadow,

    // Hover
    _hover: {
      background: "green.600",
    },

    // Active
    _active: {
      ...activeButtonStyle,
    },

    // Disabled
    _disabled: {
      ...disabledStyles.success,

      _hover: {
        _disabled: {
          ...disabledStyles.success,
        },
      },

      _active: {
        ...disabledStyles.active,
      },

      "& svg": {
        ...disabledStyles.svg,
      },
    },
  },
  error: {
    // Default
    background: "red.500",
    color: "white",
    boxShadow,

    // Hover
    _hover: {
      background: "red.600",
    },

    // Active
    _active: {
      ...activeButtonStyle,
    },

    // Disabled
    _disabled: {
      ...disabledStyles.error,

      _hover: {
        _disabled: {
          ...disabledStyles.error,
        },
      },

      _active: {
        ...disabledStyles.active,
      },

      "& svg": {
        ...disabledStyles.svg,
      },
    },
  },
  // Icon buttons and other misc. styles
  ghost: {
    _hover: {
      background: "gray.200",
    },
    _disabled: {
      ...disabledStyles.ghost,
      _hover: {
        ...disabledStyles.ghost,
        _disabled: {
          ...disabledStyles.ghost,
        },
      },
    },
  },
  ghostIconButton: {
    background: "none",
    _hover: {
      background: "none",
    },
  },
  edit: {
    position: "absolute",
    bottom: 0,
    right: 0,
    bg: "dark.800",
    borderRadius: "full",
    paddingInlineStart: 0,
    paddingInlineEnd: 0,
    height: "auto",
  },
  social: {
    bg: "dark.400",
    color: "white",
  },
  upload: {
    bg: "dark.700",
    color: "dark.200",
  },
  picker: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    w: "full",
    h: "full",
    minWidth: "full",
    minHeight: "full",
    p: 0,
    borderRadius: "inherit",
    cursor: "pointer",
  },
  agenda: {
    _hover: {
      background: "gray.100",

      "& svg": {
        color: "dark.100",
      },
    },
    _disabled: {
      ...disabledStyles.ghost,
    },
  },
  cardButton: {
    background: "gray.25",
    boxShadow: boxShadow,
    justifyContent: "stretch",
    height: "60px",
    color: "dark.600",

    _hover: {
      background: "gray.200",
      transition: "all .2s ease-in-out",
      transform: "scale(1.1)",
    },
  },
};

export default variants;
