import { useEffect } from "react";
import { useNotifications } from "@hooks";
import { Box, Fade, useDisclosure } from "@chakra-ui/react";
import { NotificationItem } from "@components";

const NotificationViewer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { chatNotification } = useNotifications();

  useEffect(() => {
    if (Object.keys(chatNotification).length > 0) {
      onOpen();

      setTimeout(() => {
        onClose();
      }, 2000);
    }
  }, [chatNotification, onOpen, onClose]);

  return (
    <Box
      width="330px"
      transition="width 0.3s cubic-bezier(0.820, 0.085, 0.395, 0.895)"
      height="calc(100vh - 600px)"
      maxHeight="12"
      position="absolute"
      bottom="300"
      left="0"
      zIndex={1}
      p="0 10px"
    >
      <Fade in={isOpen} unmountOnExit={true}>
        <NotificationItem
          notification={chatNotification}
          onClose={onClose}
          containerProps={{
            borderRadius: "12px",
            bgGradient:
              "linear-gradient(268.84deg, #253157 7.81%, #2B385F 82.5%)",
          }}
          buttonProps={{ bg: "#000C3B", color: "#555D7C" }}
        />
      </Fade>
    </Box>
  );
};

export default NotificationViewer;
