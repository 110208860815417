import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCheckExistingMeetingMutation } from "@store/api/meeting";
import { updatePlatformParticipantUuid } from "@store/profileSlice";
import { useFetchProfileQuery } from "@store/api/auth";
import { isZoomApp } from "@utils/helpers";
import { CONTEXT_STATUS, APP_TYPE } from "@constants";
import {
  configZoomSdk,
  zoomPromptAuthorize,
  authorize,
  getMeetingUuidAndUserContext,
  getZoomMediaState
} from "@sdk/zoom/utils";
import {
  onAuthorizedEvent,
  onMyActiveSpeakerChangeEvent,
  onMyReactionEvent,
  onMyMediaChangeEvent,
  onMyUserContextChangeEvent,
  onRunningContextChangeEvent
} from "@sdk/zoom/eventListeners";
import { emitOnMyMediaChange } from "@socket";

const useZoomSdk = () => {
  const [sdkConfigured, setSdkConfigured] = useState(false);
  const [userContextStatus, setUserContextStatus] = useState();
  const dispatch = useDispatch();
  const [checkExistingMeeting] = useCheckExistingMeetingMutation();
  const {
    isLoading: fetchingProfile,
    error: fetchProfileError,
    refetch,
  } = useFetchProfileQuery();
  const profileId = useSelector((state) => state.profile.data?._id);
  const platformParticipantUuidFromStore = useSelector((state) => state.profile.platformParticipantUuid);
  const isAuthenticated = useSelector((state) => state.profile.isAuthenticated);
  const meetingInstanceId = useSelector((state) => state.meeting.data.meetingObject?.meetingInstances?.[0]._id);
  const hasNoProfileOrConfiguredSdk = useMemo(() => !profileId || !sdkConfigured, [profileId, sdkConfigured]);
  const hasIncompleteData = useMemo(
    () => hasNoProfileOrConfiguredSdk || !meetingInstanceId,
    [hasNoProfileOrConfiguredSdk, meetingInstanceId]
  );
  const isAuthorized = useMemo(
    () => userContextStatus && (userContextStatus === CONTEXT_STATUS.AUTHORIZED),
    [userContextStatus]
  );

  const updateMeetingStatus = useCallback((platformParticipantUuid, platformMeetingInstanceId) => {
    checkExistingMeeting({
      platformParticipantUuid: platformParticipantUuid ?? null,
      platformMeetingInstanceId: platformMeetingInstanceId ?? null
    });
    dispatch(updatePlatformParticipantUuid(platformParticipantUuid ?? null));
  }, [checkExistingMeeting, dispatch])

  const getMeetingStatus = useCallback(async () => {
    if (!profileId || !sdkConfigured) return;

    try {
      const { platformParticipantUuid, platformMeetingInstanceId } = await getMeetingUuidAndUserContext();
      updateMeetingStatus(platformParticipantUuid, platformMeetingInstanceId)
    } catch (error) {
      updateMeetingStatus();
    }
  }, [updateMeetingStatus, sdkConfigured, profileId]);

  useEffect(() => {
    if (isZoomApp && !sdkConfigured) {
      const configureSdk = async () => {
        const configResponse = await configZoomSdk();
        setSdkConfigured(true);
        setUserContextStatus(configResponse.auth.status);
      };
      configureSdk();
    }
  }, [sdkConfigured]);

  useEffect(() => {
    if (sdkConfigured && userContextStatus && !isAuthorized) {
      zoomPromptAuthorize();
    }
  }, [sdkConfigured, userContextStatus, isAuthorized]);

  useEffect(() => {
    if (
      sdkConfigured &&
      isAuthorized &&
      !fetchingProfile &&
      fetchProfileError
    ) {
      try {
        authorize();
      } catch (e) {
        console.error(e);
      }
    }
  }, [fetchingProfile, fetchProfileError, sdkConfigured, isAuthorized]);

  useEffect(() => {
    if (!sdkConfigured) return;

    onAuthorizedEvent.addEventListener({
      onSuccessCallback: () => refetch(),
      onErrorCallback: () => {}
    });

    onRunningContextChangeEvent.addEventListener({
      onSuccessCallback: getMeetingStatus,
    });

    return () => {
      onAuthorizedEvent.removeEventListener();
      onRunningContextChangeEvent.removeEventListener();
    };
  }, [refetch, getMeetingStatus, sdkConfigured]);

  useEffect(() => {
    if (hasIncompleteData) return;

    const data = { meetingInstanceId, userId: profileId,  };

    onMyReactionEvent.addEventListener(data);
    onMyActiveSpeakerChangeEvent.addEventListener(data);
    onMyMediaChangeEvent.addEventListener(data);
    onMyUserContextChangeEvent.addEventListener({
      onSuccessCallback: () => {
        setSdkConfigured(false);
        setUserContextStatus();
      },
      userMeetingInfo: {
        meetingInstanceId,
        platformParticipantUuid: platformParticipantUuidFromStore,
      }
    });

    return () => {
      onMyReactionEvent.removeEventListener();
      onMyActiveSpeakerChangeEvent.removeEventListener();
      onMyMediaChangeEvent.removeEventListener();
      onMyUserContextChangeEvent.removeEventListener();
    };
  }, [meetingInstanceId, profileId, hasIncompleteData, platformParticipantUuidFromStore, getMeetingStatus]);

  useEffect(() => {
    if (hasNoProfileOrConfiguredSdk) return;
    getMeetingStatus();
  }, [hasNoProfileOrConfiguredSdk, getMeetingStatus, checkExistingMeeting]);

  useEffect(() => {
    if (hasIncompleteData) return;

    const defaultMediaChangeData = {
      meetingInstanceId,
      audioOn: false,
      videoOn: false,
      userId: profileId,
    };

    const getMediaState = async () => {
      try {
        const { audioOn, videoOn } = await getZoomMediaState();
        emitOnMyMediaChange({
          ...defaultMediaChangeData,
          audioOn,
          videoOn,
        });
      } catch (error) {
        emitOnMyMediaChange(defaultMediaChangeData);
      }
    }

    getMediaState();
  }, [sdkConfigured, profileId, meetingInstanceId, hasIncompleteData]);
  
  return ({
    profileId,
    isAuthenticated,
    isAuthorized,
    sdkConfigured,
    userContextStatus,
    fetchingProfile,
    fetchProfileError,
    appType: APP_TYPE.ZoomApp,
  });
}

export default useZoomSdk;
