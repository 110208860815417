import variants from "./variants";

const {
  primary,
  secondary,
  success,
  error,
  ghost,
  ghostIconButton,
  edit,
  social,
  upload,
  picker,
  agenda,
  cardButton,
} = variants;

const fontWeight = "600";
const borderRadius = {
  sm: "6px",
  lg: "8px",
};

const Button = {
  sizes: {
    xs: {
      fontSize: "10px",
      borderRadius: borderRadius.sm,
    },
    sm: {
      fontSize: "12px",
      fontWeight: fontWeight,
      borderRadius: borderRadius.sm,
    },
    md: {
      fontSize: "14px",
      fontWeight: fontWeight,
      borderRadius: borderRadius.lg,
    },
    lg: {
      fontSize: "16px",
      fontWeight: fontWeight,
      borderRadius: borderRadius.lg,
    },
  },
  variants: {
    primary,
    secondary,
    success,
    error,
    ghost,
    ghostIconButton,
    edit,
    social,
    upload,
    picker,
    agenda,
    cardButton,
  },
};

export default Button;
