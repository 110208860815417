import { useState, useCallback } from "react";
import { useValidateMutation } from "@store/api/payments";
import { useNavigate } from "react-router-dom";
import { useToastNotification } from "@hooks";
import { TRIAL_CODE } from "@constants";
import { Flex, Heading, Image, Text, Button, Grid } from "@chakra-ui/react";
import { Card } from "@components";

const ProCard = ({
  heading,
  image,
  text,
  index,
  handleClick,
  primaryAction,
  secondaryAction,
  children,
}) => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToastNotification();
  const [loading, setIsLoading] = useState(false);
  const [validateCode] = useValidateMutation();
  const trialExpired = primaryAction && secondaryAction;

  const content = () => {
    if (trialExpired) {
      return (
        <Grid templateColumns="repeat(2, 1fr)" gap="1" w="100%">
          <Button
            size="sm"
            variant="secondary"
            isFullWidth
            onClick={secondaryAction}
          >
            Contact
          </Button>
          <Button
            size="sm"
            variant="primary"
            isFullWidth
            onClick={primaryAction}
          >
            Join Pro ✨
          </Button>
        </Grid>
      );
    }

    return index !== 2 ? (
      <Button size="sm" variant="primary" isFullWidth onClick={handleClick}>
        Next
      </Button>
    ) : (
      <Button
        size="sm"
        variant="primary"
        isFullWidth
        onClick={handleValidation}
        isLoading={loading}
        loadingText="Activating"
      >
        Start Free Trial
      </Button>
    );
  };

  const handleValidation = useCallback(async () => {
    setIsLoading(true);

    try {
      await validateCode({ accessCode: TRIAL_CODE }).unwrap();
      showSuccessToast("Free trial activated successfully");
      navigate("/pro/agendas");
      navigate(0);
    } catch (error) {
      console.log(error);
      showErrorToast(
        "There was an error activating your free trial. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }, [showSuccessToast, showErrorToast, setIsLoading, navigate, validateCode]);

  const renderContent = content();

  return (
    <Card
      bg="gray.25"
      border="2px solid"
      borderColor="gray.100"
      boxShadow="0px 10px 20px -5px rgba(16, 24, 40, 0.18)"
      borderRadius="24px"
      my="50%"
      mx="3"
    >
      <Flex flexDirection="column" alignItems="center">
        <Heading variant="proOnboardingHeading">{heading}</Heading>
        <Flex direction="column" alignItems="center" p={5}>
          <Image src={image} />
          <Text variant="proOnboardingText">{text}</Text>
          {children}
          {renderContent}
        </Flex>
      </Flex>
    </Card>
  );
};

export default ProCard;
