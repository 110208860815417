import axios from "axios";
import zoomSdk from "@zoom/appssdk";

export const configZoomSdk = () => {
  return zoomSdk.config({
    capabilities: [
      "getSupportedJsApis",
      "openUrl",
      "authorize",
      "onAuthorized",
      "onMyReaction",
      "onMyActiveSpeakerChange",
      "getUserContext",
      "onMyUserContextChange",
      "promptAuthorize",
      "onMyMediaChange",
      "onRunningContextChange",
    ],
  });
};

export const zoomPromptAuthorize = () => {
  zoomSdk.promptAuthorize();
}

export const createEventListener = (eventName, eventHandler) => {
  let eventHandlerFunction;
  return ({
    addEventListener: (additionalData = null) => {
      eventHandlerFunction = (event) => {
        eventHandler(event, additionalData);
      };
      zoomSdk.addEventListener(eventName, eventHandlerFunction)
    },
    removeEventListener: () => zoomSdk.removeEventListener(eventName, eventHandlerFunction)
  })
};

export const sendZoomRequest = async (method, path, data = null) => {
  return await axios({
    method,
    url: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}${path}`,
    withCredentials: true,
    data: data ?? {},
  }).then((res) => res.data);
}

export const authorize = async () => {
  const authorizeResponse = await sendZoomRequest("GET", '/user/client/authorize');
  
  if (!authorizeResponse || !authorizeResponse.codeChallenge) {
    return false;
  }

  const { codeChallenge, state } = authorizeResponse;

  try {
    const authorizeOptions = {
      codeChallenge: codeChallenge,
      state: state,
    };
    await zoomSdk.authorize(authorizeOptions);
    return true;
  } catch (error) {
    return false;
  }
};

export const getMeetingUuidAndUserContext = async () => {
  const [userContext, zoomMeetingUuid] = await Promise.all([
    zoomSdk.getUserContext(),
    zoomSdk.getMeetingUUID()
  ]);

  return {
    platformParticipantUuid: userContext.participantUUID,
    platformMeetingInstanceId: zoomMeetingUuid.meetingUUID,
  }
}

export const getZoomMediaState = async () => {
  const [videoEvent, audioEvent] = await Promise.all([
    zoomSdk.getVideoState(),
    zoomSdk.getAudioState()
  ]);

  return { audioOn: audioEvent.audio, videoOn: videoEvent.video };
}

export const openURLFromZoom = async (url) => await zoomSdk.openUrl({ url });

export { zoomSdk };
