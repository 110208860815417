import { Text, Image } from "@chakra-ui/react";
import stepTwoImage from "@assets/images/host-onboarding-step2.gif";
import stepThreeImage from "@assets/images/host-onboarding-step3.gif";
import stepFourImage from "@assets/images/host-onboarding-step4.gif";

export const TOUR_SAMPLE_AGENDA = {
  _id: "640a1b0b69b7086d55f776e0",
  title: "Sample Agenda",
};

export const TOUR_STEPS = [
  {
    target: "body",
    placement: "center",
    title: "Welcome to Townhall Pro✨",
    content: (
      <>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          Ready to know if your audience is paying attention?
        </Text>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          In this quick tutorial, we'll show you the important steps, so you can
          get started right away.
        </Text>
      </>
    ),
  },
  {
    target: "#host-onboarding-step-1 > li:nth-child(1)",
    content: (
      <>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          Townhall is organized through agendas. Let's take a look at the
          "Sample Agenda".
        </Text>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          <strong>
            <em>Hint:</em>
          </strong>{" "}
          Copy an agenda to save time.
        </Text>
      </>
    ),
  },
  {
    target: "#host-onboarding-step-2",
    content: (
      <>
        <Image src={stepTwoImage} mb="2" />
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          To get instant and continuous feedback on audience engagement, click
          the "Go Live" button.
        </Text>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          <strong>
            <em>Hint:</em>
          </strong>{" "}
          Only the Host can run the E-Pulse. If the "Go Live" button is grey,
          you are either not the Host or you are accessing a past Agenda (like
          in this Weekly All Hands meeting).
        </Text>
      </>
    ),
  },
  {
    target: "#host-onboarding-step-2",
    offset: -100,
    content: (
      <>
        <Image src={stepThreeImage} mb="2" />
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          With the E-Pulse, you can read the room even if cameras and mics are
          off!
        </Text>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          Is engagement dropping? Add an Activity like a <strong>poll</strong>{" "}
          or <strong>quiz</strong>.
        </Text>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          <strong>
            <em>Hint:</em>
          </strong>{" "}
          You can set up the Activities in an Agenda before you start your
          meeting.
        </Text>
      </>
    ),
  },
  {
    target: "#host-onboarding-step-2",
    offset: -100,
    content: (
      <>
        <Image src={stepFourImage} mb="2" />
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          You can see that giving that quiz really made an impact on the
          E-Pulse!
        </Text>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          After you "Stop" the Agenda, you can check out the data in the
          E-Report.
        </Text>
      </>
    ),
  },
  {
    target: ".host-onboarding-step-4",
    content: (
      <>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          Townhall PRO provides a summary E-Report instantly! You can also view
          the detailed report to look at performance and attendance.
        </Text>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          <strong>
            <em>Hint:</em>
          </strong>{" "}
          The full E-Report can be downloaded in .csv or .pdf format.
        </Text>
      </>
    ),
  },
  {
    target: "body",
    placement: "center",
    title: "Success!",
    content: (
      <>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          You're now a PRO host. A sample E-Report is open in a new browser tab
          and we sent a copy to your email.
        </Text>
        <Text fontSize="12px" fontWeight="400" color="dark.400" mb="2">
          You can host an unlimited number of events during your 2 week FREE
          Trial!
        </Text>
      </>
    ),
  },
];
