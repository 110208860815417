import { Button, Flex, Text } from "@chakra-ui/react";

const SendLink = ({ text, handleClick, beforeIcon, afterIcon, styles }) => {
  return (
    <Button
      background="gray.100"
      color="dark.400"
      fontWeight="normal"
      padding={2}
      onClick={handleClick}
      width="100%"
      {...styles}
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Flex alignItems="center" width="calc(100% - 25px)">
          {beforeIcon}
          <Text fontSize="12px" isTruncated>
            {text}
          </Text>
        </Flex>
        {afterIcon}
      </Flex>
    </Button>
  );
};

export default SendLink;
