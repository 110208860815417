import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import {
  removeIconsFromChatBox,
  updateIconViewBoxCord,
} from "../../store/iconSlice";
import { Box } from "@chakra-ui/react";
import SVG from "react-inlinesvg";
import "./styles.css";

const IconViewer = () => {
  const dispatch = useDispatch();
  const icons = useSelector((state) => state.icons.iconsChatBox);
  const { width, height, ref: iconBoxRef } = useResizeDetector();

  useEffect(() => {
    if (!iconBoxRef.current) return;
    dispatch(updateIconViewBoxCord({ width, height }));
  }, [iconBoxRef, width, height, dispatch]);

  useEffect(() => {
    const inter = setInterval(() => {
      if (icons.length > 0) {
        dispatch(removeIconsFromChatBox());
      }
    }, 0);

    return () => clearInterval(inter);
  }, [dispatch, icons]);

  return (
    <Box
      ref={iconBoxRef}
      transition="width 0.3s cubic-bezier(0.820, 0.085, 0.395, 0.895)"
      height="inherit"
    >
      {icons.map((icon) => {
        const { left, url, color, size } = icon;

        return (
          <SVG
            key={left}
            src={url}
            width={`${size}px`}
            height={`${size}px`}
            fill={color}
            className="animate-svg"
            style={{
              position: "absolute",
              left: `${left}%`,
              top: `calc(${height}px - 30px)`,
            }}
          />
        );
      })}
    </Box>
  );
};

export default IconViewer;
