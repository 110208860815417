import { Button, Icon, Image } from "@chakra-ui/react";
import { BiChevronRight } from "react-icons/bi";

const CardButton = ({ icon, label, handleClick }) => {
  return (
    <Button
      size="md"
      isFullWidth
      variant="cardButton"
      leftIcon={<Image boxSize="5" src={icon} />}
      onClick={handleClick}
    >
      {label}
      <Icon pos="absolute" right="10px" color="dark.200" as={BiChevronRight} />
    </Button>
  );
};

export default CardButton;
