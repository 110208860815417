import React from "react";
import { chakra, useCheckbox, Flex, Icon, Text } from "@chakra-ui/react";
import { IoCheckmarkSharp } from "react-icons/io5";

const Checkbox = (props) => {
  const { state,
    getCheckboxProps,
    getInputProps,
    getLabelProps,
    htmlProps,
  } = useCheckbox(props);

  const handleOnKeyDown = (event) => {
    if (event.key === " ") {
      getInputProps().onChange();
    }
  };

  return (
    <chakra.label
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      gridColumnGap={2}
      cursor="pointer"
      p={2} 
      background={state.isChecked ? "success" : "gray.50"}
      borderBottom={state.isChecked ? "5px solid var(--chakra-colors-green-600)" : "5px solid transparent"}
      mt={0}
      borderRadius={5}
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Text color={state.isChecked ? "white" : "gray.600"} {...getLabelProps()}>{props.children}</Text>
      <Flex
        alignItems='center'
        justifyContent='center'
        border='2px solid'
        borderColor={ state.isChecked ? "green.600" : "gray.300"}
        background="white"
        w={4}
        h={4}
        {...getCheckboxProps()}
        tabIndex={0}
        role="checkbox"
        aria-checked={state.isChecked}
        onKeyDown={handleOnKeyDown}
      >
        {state.isChecked && <Icon as={IoCheckmarkSharp} color="green.600" w={3.5} h={3.5} />}
      </Flex>
    </chakra.label>
  );
};

export default Checkbox;

