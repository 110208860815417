import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addAgendas, updateAgendas } from "@store/agendasSlice";
import { useNavigate } from "react-router-dom";
import { updateRunTour, updateTourStepIndex } from "@store/tourSlice";
import { useAuth } from "@hooks";
import { useUpdateOnboardingMutation } from "@store/api/auth";

const useTour = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useAuth();
  const [updateOnboarding] = useUpdateOnboardingMutation();
  const isHostOnboarded = profile.onboarding?.host;
  const isBasicUser = profile.licenseType === "Basic";
  const runTour = useSelector((state) => state.tour.runTour);
  const stepIndex = useSelector((state) => state.tour.stepIndex);

  const handleRunTour = (run) => dispatch(updateRunTour(run));
  const handleStepIndex = (stepIndex) => {
    dispatch(updateTourStepIndex(stepIndex));
  };
  const handleTourCallback = useCallback((data) => {
    const { action, index, type } = data;

    if (type === "step:after" && index === 0) {
      handleStepIndex(1);
    } else if (type === "step:after" && index === 1) {
      handleRunTour(false);
      navigate("/pro/agendas/640a1b0b69b7086d55f776e0");

      if (action === "next") {
        handleRunTour(true);
      }
    } else if (type === "step:after" && index === 2) {
      if (action === "next") {
        handleStepIndex(3);
      }
    } else if (type === "step:after" && index === 3) {
      if (action === "next") {
        handleStepIndex(4);
      }
    } else if (type === "step:after" && index === 4) {
      if (action === "next") {
        handleStepIndex(5);
      }
    } else if (type === "step:after" && index === 5) {
      if (action === "next") {
        handleStepIndex(6);
      }
    } else if (type === "tour:end") {
      handleRunTour(false);
      navigate("/pro/agendas/");
      dispatch(addAgendas([]));

      try {
        updateOnboarding({ host: true });
        dispatch(updateAgendas({ _id: "640a1b0b69b7086d55f776e0" }));
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    if (!isHostOnboarded && !isBasicUser) {
      handleRunTour(true);
    }
  }, [isHostOnboarded, isBasicUser]);

  return {
    runTour,
    stepIndex,
    handleRunTour,
    handleStepIndex,
    handleTourCallback,
    isHostOnboarded,
  };
};

export default useTour;
