import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Accordion } from "@chakra-ui/react";
import { SendView } from "@components/ActivityTypes/Send";
import { useSdkUtils } from "@hooks";
import { useRespondToActivityMutation } from "@store/api/activities";

const ActivityToolAccordion = () => {
  const [respondToActivity] = useRespondToActivityMutation();
  const { openUrl } = useSdkUtils();
  const liveTools = useSelector((state) => state.activity.liveTools);
  const profile = useSelector((state) => state.profile.data);
  const meeting = useSelector((state) => state.meeting.data?.meetingObject);

  const displayableActivityTools = useMemo(
    () =>
      liveTools.filter((liveTool) => {
        if (!liveTool?.settings?.blast) {
          return !!liveTool?.settings?.initialParticipant.includes(profile._id);
        }
        return true;
      }),
    [liveTools, profile._id]
  );

  const handleClick = useCallback(
    async (activity) => {
      await openUrl(activity.options[0].option);
      if (activity?.response?.find((res) => res.user.userId === profile._id)) {
        return;
      }
      try {
        respondToActivity({
          id: activity._id,
          body: { response: [activity.options[0]._id] },
        });
      } catch (error) {}
    },
    [respondToActivity, profile._id, openUrl]
  );

  if (profile?._id === meeting?.hostId) {
    return null;
  }

  return (
    <Accordion allowToggle={true} defaultIndex={0}>
      {[...displayableActivityTools].reverse().map((activity) => (
        <SendView
          key={activity._id}
          activity={activity}
          handleClick={handleClick}
        />
      ))}
    </Accordion>
  );
};

export default ActivityToolAccordion;
