import {
  Text,
  CircularProgress,
  CircularProgressLabel,
  Center,
  chakra,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { SendLink } from "@components/ActivityTypes/Send";
import { useSdkUtils } from "@hooks";
import { getPercentage } from "@utils/helpers";
import { LinkIcon, ArrowUpRight } from "@components/Svg";

const SendResults = ({ title, options, response, numberOfParticipants, settings }) => {
  const { openUrl } = useSdkUtils();
  const meetingData = useSelector((state) => state.meeting.data);
  const hostId = meetingData?.meetingObject?.hostId;
  const allParticipants = meetingData?.meetingObject?.meetingInstances[0]?.participantsOpenedTH;
  const activityParticipants = settings.blast
  ? (allParticipants?.filter((participant) => participant !== hostId).length || 0)
  : settings?.initialParticipant?.length - 1 || 0;
  const participantNumber = numberOfParticipants || activityParticipants;
  const responseNumber = response.length;
  const percentage = getPercentage(responseNumber, participantNumber);
  const roundedPercentage = percentage?.toFixed(0) || 0;

  return (
    <>
      <Text fontWeight="600" fontSize="12px" color="dark.400" my={2}>
        {title}
      </Text>
      <SendLink
        text={options[0].option}
        handleClick={() => openUrl(new URL(options[0].option))}
        beforeIcon={<LinkIcon color="dark.200" mr={2} width="12px" />}
        afterIcon={<ArrowUpRight ml={1} color="green.500" mr={1} width="10px" />}
      />
      <Center m="5px 0">
        <CircularProgress
          value={roundedPercentage}
          size="120px"
          color="blue.500"
          thickness="10px"
          capIsRound={true}
          trackColor="blue.50"
        >
          <CircularProgressLabel
            fontSize="16px"
            fontWeight="700"
            color="gray.800"
            display="inline-grid"
          >
            {roundedPercentage}%
            <chakra.span fontSize="10px" fontWeight="400" color="gray.400">
              Viewed
            </chakra.span>
          </CircularProgressLabel>
        </CircularProgress>
      </Center>
    </>
  );
};

export default SendResults;
