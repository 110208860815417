import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useLoginUserMutation,
  useOAuthSignInMutation,
  useSignUpUserMutation,
  useResendVerifyEmailMutation,
  useVerifyEmailMutation,
  useVerifyOnboardingMutation,
  useUpdateIsFirstViewMutation,
  useResetPasswordEmailMutation,
  useResetPasswordMutation,
  useUpdateAvatarMutation,
  useUpdateColorMutation,
  useUpdateProfileMutation,
} from "../store/api/auth";
import { logOutUser } from "../store/profileSlice";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.profile.isAuthenticated);
  const isOnboarded = useSelector((state) => state.profile.isOnboarded);
  const profile = useSelector((state) => state.profile.data);
  const [userProfile, setUserProfile] = useState(profile);
  const [accessToken, setAccessToken] = useState(null);
  const token = localStorage.getItem("jwtToken");
  const toast = useToast();

  const [oAuthSignIn, localOAuthSignInObj] = useOAuthSignInMutation();
  const [triggerLocalLogin, localLoginObj] = useLoginUserMutation();
  const [triggerLocalRegister, localRegisterObj] = useSignUpUserMutation();
  const [triggerResendVerifyEmail, localResendVerifyEmailObj] =
    useResendVerifyEmailMutation();
  const [triggerVerifyEmail, localVerifyEmailObj] = useVerifyEmailMutation();
  const [triggerVerifyOnboarding, localVerifyOnboardingObj] =
    useVerifyOnboardingMutation();
  const [triggerUpdateIsFirstView, localUpdateIsFirstViewObj] =
    useUpdateIsFirstViewMutation();
  const [triggerResetPasswordEmail, resetPasswordEmailObj] =
    useResetPasswordEmailMutation();
  const [triggerResetPassword, resetPasswordObj] = useResetPasswordMutation();
  const [triggerUpdateAvatar, updateAvatarObj] = useUpdateAvatarMutation();
  const [triggerUpdateColor, updateColorObj] = useUpdateColorMutation();
  const [triggerUpdateProfile, updateProfileObj] = useUpdateProfileMutation();

  useEffect(() => {
    if (token) {
      try {
        const accessToken = JSON.parse(token).accessToken;
        setAccessToken(accessToken);
      } catch (err) {
        console.log("Error: ", err.message);
      }
    } else {
      setAccessToken(null);
    }
  }, [token]);

  useEffect(() => {
    if (profile) {
      setUserProfile(profile);
    }
  }, [profile]);

  const googleOAuthLogin = async (data) => {
    return oAuthSignIn(data)
      .unwrap()
      .then((res) => {
        localStorage.setItem("jwtToken", JSON.stringify(res.tokens));
        navigate("/");
        return res;
      });
  };

  const localLogin = async (data) => {
    return triggerLocalLogin(data)
      .unwrap()
      .then(async (res) => {
        localStorage.setItem("jwtToken", JSON.stringify(res.tokens));
        return res.user;
      });
  };

  const localAuthRegister = async (data) => {
    return triggerLocalRegister(data)
      .unwrap()
      .then((res) => {
        localStorage.setItem("jwtToken", JSON.stringify(res.tokens));
        return res;
      });
  };

  const localResendVerifyEmail = async (data) => {
    return triggerResendVerifyEmail(data)
      .unwrap()
      .then((res) => {
        localStorage.setItem("jwtToken", JSON.stringify(res.tokens));
        return res;
      });
  };

  const localVerifyEmail = async (data) => {
    return triggerVerifyEmail(data)
      .unwrap()
      .then((res) => {
        localStorage.setItem("jwtToken", JSON.stringify(res.tokens));
        return res;
      });
  };

  const localVerifyOnboarding = async (data) => {
    return triggerVerifyOnboarding(data);
  };

  const updateAvatar = async (data) => {
    return triggerUpdateAvatar(data).then((res) => {
      toast({
        description: "User avatar updated",
        status: "success",
        position: "top",
        duration: 3000,
      });

      return res;
    });
  };

  const updateProfile = async (data) => {
    return triggerUpdateProfile(data);
  };

  const updateColor = async (data) => {
    return triggerUpdateColor(data).then((res) => {
      toast({
        description: "User color updated",
        status: "success",
        position: "top",
        duration: 3000,
      });

      return res;
    });
  };

  const localUpdateIsFirstView = async (data) => {
    return triggerUpdateIsFirstView(data);
  };

  const resetPasswordEmail = async (data) => {
    return triggerResetPasswordEmail(data)
      .unwrap()
      .then((res) => {
        localStorage.setItem("jwtToken", JSON.stringify(res.tokens));
        return res;
      });
  };

  const resetPassword = async (data) => {
    return triggerResetPassword(data)
      .unwrap()
      .then((res) => {
        localStorage.setItem("jwtToken", JSON.stringify(res.tokens));
        navigate("/login");
        toast({
          description: "Password reset is successful. Please login to continue",
          status: "success",
          position: "top",
        });

        return res;
      });
  };

  const logOut = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("isOnboarded");
    dispatch(logOutUser());
  };

  return {
    isAuthenticated,
    isOnboarded,
    googleOAuthLogin,
    localOAuthSignInObj,
    logOut,
    token,
    toast,
    profile,
    userProfile,
    accessToken,
    setAccessToken,
    localLogin,
    localLoginObj,
    localAuthRegister,
    localRegisterObj,
    localResendVerifyEmail,
    localResendVerifyEmailObj,
    localVerifyEmail,
    localVerifyEmailObj,
    localVerifyOnboarding,
    localVerifyOnboardingObj,
    localUpdateIsFirstView,
    localUpdateIsFirstViewObj,
    resetPasswordEmail,
    resetPasswordEmailObj,
    resetPassword,
    resetPasswordObj,
    updateAvatar,
    updateAvatarObj,
    updateColor,
    updateColorObj,
    updateProfile,
    updateProfileObj,
  };
};

export default useAuth;
