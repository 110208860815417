import { useSidebar } from "@hooks";
import { Image, ButtonGroup, IconButton } from "@chakra-ui/react";
import { UserOnboarding } from "@components";
import toggleListIcon from "@assets/svg/icon-toggle-user.svg";
import toggleAvatarsIcon from "@assets/svg/icon-toggle-list.svg";

const SidebarToggle = ({
  tooltipCounter,
  incrementTooltipCounter,
  isFirstView,
}) => {
  const { participantView, handleParticipantViewToggle } = useSidebar();

  return (
    <ButtonGroup>
      <UserOnboarding
        isOpen={isFirstView && tooltipCounter === 3}
        placement="left"
        onClick={incrementTooltipCounter}
        title="Customize"
        textContent="Toggle between expanded or condensed view"
        step="3 of 3"
        buttonText="Complete"
      >
        <IconButton
          variant="sidebar"
          aria-label="Show by Avatars"
          size="xs"
          icon={
            <Image
              src={toggleAvatarsIcon}
              opacity={participantView === "avatar" ? 1 : 0.5}
            />
          }
          bg={participantView === "avatar" ? "dark.400" : "transparent"}
          onClick={() => handleParticipantViewToggle("avatar")}
        />
      </UserOnboarding>
      <IconButton
        variant="sidebar"
        aria-label="Show by List"
        size="xs"
        icon={
          <Image
            src={toggleListIcon}
            opacity={participantView === "list" ? 1 : 0.5}
          />
        }
        bg={participantView === "list" ? "dark.400" : "transparent"}
        onClick={() => handleParticipantViewToggle("list")}
      />
    </ButtonGroup>
  );
};

export default SidebarToggle;
