import React, { useEffect, useCallback, useReducer, useMemo } from "react";
import {
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Flex,
  Box,
  Image,
  Text,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import { useMeeting } from "@hooks";
import { Card, CardFooter, Avatar, Duration } from "@components";
import { QuizView, QuizResult, QuizFooter, PollFooter, PollView, PollResult } from "@components/ActivityTypes";
import { useRespondToActivityMutation } from "@store/api/activities";
import { activityTypes } from "@constants/activities";
import {
  prepareStateForLiveActivity,
  addUserResponse,
} from "@store/activitySlice";

import stopwatchIcon from "@assets/stopwatch-icon.svg";

const participantModalPartsObject = {
  [activityTypes.quiz]: {
    view: QuizView,
    footer: QuizFooter,
    result: QuizResult,
  },
  [activityTypes.poll]: {
    view: PollView,
    footer: PollFooter,
    result: PollResult,
  },
};

const getModalParts = (type) => participantModalPartsObject[type] || {};

const ActivityParticipantModal = () => {
  const initialModalState = useMemo(
    () => ({
      isOpen: false,
      userResponse: [],
      isValidInput: false,
      otherProps: {},
    }),
    []
  );

  const [modalState, setModalState] = useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    initialModalState
  );
  const dispatch = useDispatch();
  const { isHostOrCoHost } = useMeeting();
  const [respondToActivity] = useRespondToActivityMutation();
  const meetingData = useSelector((state) => state.meeting.data);
  const profile = useSelector((state) => state.profile.data);
  const liveActivity = useSelector((state) => state.activity.liveActivity);
  const showResult = useSelector((state) => state.activity.showResult);
  const cachedTitle = useSelector((state) => state.activity.cachedTitle);
  const cachedType = useSelector((state) => state.activity.cachedType);

  const host = useMemo(
    () => isHostOrCoHost(profile?._id) ? profile : meetingData.meetingHost,
    [isHostOrCoHost, meetingData.meetingHost, profile]
  );
  const participantModalParts = useMemo(
    () => getModalParts(liveActivity.type || cachedType),
    [liveActivity.type, cachedType]
  );
  const ViewComponent = participantModalParts.view;
  const FooterComponent = participantModalParts.footer;
  const ResultComponent = participantModalParts.result;
  const isParticipant = profile?._id !== host._id;

  const hasResponded = useCallback(
    (responseArray = []) =>
      !!responseArray.find(
        (response) => response?.user?.userId === profile?._id
      ),
    [profile?._id]
  );

  useEffect(() => {
    if (!liveActivity || !isParticipant) {
      return;
    }

    if (liveActivity.live && !hasResponded(liveActivity.response)) {
      setModalState({ isOpen: true });
    } else {
      setModalState({ isOpen: false });
    }
  }, [
    liveActivity,
    profile,
    meetingData,
    showResult,
    isParticipant,
    hasResponded,
  ]);

  useEffect(() => {
    if (isParticipant && showResult) {
      setModalState({ isOpen: true });
    }
  }, [showResult, isParticipant]);

  const handleSubmit = useCallback(() => {
    try {
      dispatch(addUserResponse({ userResponse: modalState.userResponse }));
      respondToActivity({
        id: liveActivity._id,
        body: { response: modalState.userResponse },
      });
      setModalState({ isOpen: false });
    } catch (error) {}
  }, [modalState.userResponse, liveActivity._id, dispatch, respondToActivity]);

  const backgroundColor = (showResult && cachedType === activityTypes.quiz)
    ? (modalState?.otherProps?.isCorrect ? "green.50" : "red.50" )
    : "white";

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={modalState.isOpen}
      onClose={() => {
        dispatch(prepareStateForLiveActivity());
        setModalState({ isOpen: false });
      }}
      isCentered
      size="sm"
    >
      <ModalOverlay />
      <ModalContent bg="transparent">
        <ModalBody position="relative">
          <Card
            p={4}
            border="none"
            borderRadius="10px 10px 0 0"
            bg="white"
            footer={
              <CardFooter
                p={4}
                background={backgroundColor || "white"}
                border="none"
              >
                {FooterComponent && (
                  <FooterComponent
                    otherProps={modalState?.otherProps}
                    isValidInput={modalState.isValidInput}
                    handleSubmit={handleSubmit}
                  />
                )}
              </CardFooter>
            }
          >
            {showResult && (
              <ModalCloseButton
                position="absolute"
                zIndex={9999}
                background="white"
                borderRadius="full"
                top="-8px"
                right="10px"
                _hover={{
                  background: "gray.100",
                }}
              />
            )}
            <Flex justifyContent="space-between">
              <Flex alignItems="center">
                <Avatar
                  showBorder={true}
                  size="sm"
                  mr={2}
                  src={host?.avatar}
                  color="gray.100"
                  rounded
                  darkIcon
                />
                <Text fontWeight="400" fontSize="12px" color="gray.400">
                  By {`${host?.firstName} ${host?.lastName}`}
                </Text>
              </Flex>
              <Flex alignItems="center">
                {liveActivity?.live ? (
                  <Countdown
                    date={dayjs(liveActivity?.startTime)
                      .add(liveActivity?.settings?.duration, "minute")
                      .toDate()}
                    renderer={Duration}
                    onComplete={() => setModalState({ isOpen: false })}
                  />
                ) : (
                  Duration({ minutes: "0", seconds: "00" })
                )}
                <chakra.span>
                  <Image src={stopwatchIcon} alt="stopwatch" />
                </chakra.span>
              </Flex>
            </Flex>
            <Box background="gray.50" p={2} mt={2} mb={4} borderRadius={5}>
              <Text fontWeight="600" color="dark.400">
                {liveActivity.title || cachedTitle}
              </Text>
            </Box>
            <Box>
              {
                showResult ? (
                  ResultComponent ? (
                    <ResultComponent title={liveActivity.title} setModalState={setModalState} />
                  ) : null
                ) : (
                  ViewComponent && (
                    <ViewComponent
                      setModalState={setModalState}
                      options={liveActivity.options}
                    />
                  )
                )
              }
            </Box>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ActivityParticipantModal;
