import {
  Modal as ChakraModal,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalCloseButton,
  Divider,
} from "@chakra-ui/react";

const Modal = ({
  isOpen,
  onClose,
  size,
  showCloseButton,
  heading,
  footer,
  children,
  ...restProps
}) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={size}
      {...restProps}
    >
      <ModalOverlay />
      <ModalContent borderRadius="12px">
        {heading && <ModalHeader fontWeight="600" fontSize="sm" color="dark.400">{heading}</ModalHeader>}
        {showCloseButton && <ModalCloseButton />}
        {heading && <Divider /> }
        <ModalBody pt="4" pb="4">
          {children}
        </ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
