import { createStandaloneToast } from "@chakra-ui/toast";
import { showErrorToast, showSuccessToast } from "@store/customActions";

const notificationMiddlewares = (api) => (next) => (action) => {
  const toast = createStandaloneToast();

  if (showSuccessToast.type === action.type) {
    toast({
      description: action.payload,
      position: "top",
      status: "success",
    });
  }

  if (showErrorToast.type === action.type) {
    toast({
      description: action.payload,
      position: "top",
      status: "error",
    });
  }

  return next(action);
};

export default notificationMiddlewares;
