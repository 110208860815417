import { Text, Flex } from "@chakra-ui/react";
import { Card } from "@components";
import EPulseTimer from "./EPulseTimer";

const EPulseContainer = ({ agenda, children }) => {
  return (
    <Card p="10px" position="relative" id="step4">
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Flex flexDirection="column">
          <Text fontWeight="600" color="dark.500">
            E-Pulse
          </Text>
          <Text fontSize="x-small" fontWeight="500" color="dark.200" mb="2">
            Real-Time Audience Engagement
          </Text>
        </Flex>
        <Flex alignItems="center">
          <EPulseTimer agenda={agenda} />
        </Flex>
      </Flex>
      {children}
    </Card>
  );
};

export default EPulseContainer;
