import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useCreateNewAgendaMutation,
  useDuplicateAgendaMutation,
  useDeleteAgendaMutation,
  useFetchAgendasQuery,
} from "@store/api/agenda";
import {
  addAgendas,
  addPastAgendas,
  updateAgendas,
  updateCurrentAgenda,
} from "@store/agendasSlice";
import { useToastNotification, useTour } from "@hooks";
import { Box, Button, Icon } from "@chakra-ui/react";
import { Tabs, AlertDialog, AgendaList, EmptyState } from "@components";
import { DEFAULT_AGENDA_TITLE } from "@constants";
import { TOUR_SAMPLE_AGENDA } from "@constants/tour";
import { RiAddCircleFill } from "react-icons/ri";
import duplicateIcon from "@assets/svg/icon-duplicate.svg";
import currentAgendaEmptyImage from "@assets/current-agenda-empty.svg";
import pastAgendaEmptyImage from "@assets/past-agenda-empty.svg";

const AgendaContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { runTour } = useTour();
  const { showSuccessToast, showErrorToast } = useToastNotification();
  const currentAgendas = useSelector((state) => state.agendas.agendas);
  const [agendaDeleteModalOpen, setAgendaDeleteModalOpen] = useState(false);
  const [agendaDuplicateModalOpen, setAgendaDuplicateModalOpen] =
    useState(false);
  const [agendaId, setAgendaId] = useState("");
  const pastAgendas = useSelector((state) => state.agendas.pastAgendas);
  const profile = useSelector((state) => state.profile.data);
  const { appType } = profile;
  const basicUser = appType === "Basic";
  const [createAgenda, { data: createdAgenda, isLoading: creatingAgenda }] =
    useCreateNewAgendaMutation();
  const [
    duplicateAgenda,
    {
      data: duplicatedAgenda,
      error: duplicatedAgendaError,
      isLoading: duplicatingAgenda,
    },
  ] = useDuplicateAgendaMutation();
  const [
    deleteAgenda,
    { error: deleteAgendaError, isLoading: deletingAgenda },
  ] = useDeleteAgendaMutation();
  const {
    data: agendaData,
    isLoading: loadingAgendas,
    refetch: refetchAgendas,
  } = useFetchAgendasQuery(
    {},
    { skip: basicUser, refetchOnMountOrArgChange: true }
  );

  const handleDuplicateAgenda = async () => {
    try {
      await duplicateAgenda(agendaId).unwrap();
      refetchAgendas();
      showSuccessToast("Duplicated agenda successfully");
    } catch (error) {
      showErrorToast(error.data?.message || duplicatedAgendaError);
    } finally {
      setAgendaDuplicateModalOpen(false);
    }
  };

  const handleDeleteAgenda = async () => {
    try {
      await deleteAgenda(agendaId).unwrap();
      dispatch(updateAgendas({ _id: agendaId }));
      dispatch(updateCurrentAgenda({}));
      showSuccessToast("Deleted agenda successfully");
    } catch (error) {
      showErrorToast(error.data?.message || deleteAgendaError);
    } finally {
      setAgendaDeleteModalOpen(false);
    }
  };

  const agendaTabs = [
    {
      id: 0,
      label: "Agendas",
      content: (
        <AgendaList
          loadingAgendas={loadingAgendas}
          agendas={currentAgendas}
          setAgendaId={setAgendaId}
          setAgendaDeleteModalOpen={setAgendaDeleteModalOpen}
          setAgendaDuplicateModalOpen={setAgendaDuplicateModalOpen}
          emptyStateComponent={
            <EmptyState
              container="true"
              containerProps={{
                background:
                  "linear-gradient(268.62deg, rgba(137, 18, 202, 0.03) -50.64%, rgba(62, 0, 234, 0.03) 73.74%)",
              }}
              imgSrc={currentAgendaEmptyImage}
              imgProps={{ boxSize: "50px" }}
              text="Your current agenda panel is empty"
              textProps={{
                mt: "12px",
                textAlign: "center",
              }}
            />
          }
        />
      ),
    },
    {
      id: 1,
      label: "Past Agendas",
      content: (
        <AgendaList
          agendas={pastAgendas}
          isPastAgenda={true}
          setAgendaId={setAgendaId}
          setAgendaDuplicateModalOpen={setAgendaDuplicateModalOpen}
          emptyStateComponent={
            <EmptyState
              container="true"
              imgSrc={pastAgendaEmptyImage}
              imgProps={{ boxSize: "50px" }}
              text="Your past agenda panel is empty"
              textProps={{
                mt: "12px",
                textAlign: "center",
              }}
            />
          }
        />
      ),
    },
  ];

  useEffect(() => {
    if (agendaData) {
      if (runTour) {
        dispatch(
          addAgendas({
            agendas: [TOUR_SAMPLE_AGENDA, ...agendaData.agendas.agendas],
          })
        );
      } else {
        dispatch(addAgendas({ agendas: agendaData.agendas.agendas }));
        dispatch(
          addPastAgendas({ pastAgendas: agendaData.agendas.pastAgendas })
        );
      }
    }
  }, [agendaData, dispatch, runTour]);

  useEffect(() => {
    if (createdAgenda?.agenda) {
      navigate(`/pro/agendas/${createdAgenda.agenda._id}`);
    }

    if (duplicatedAgenda) {
      navigate(`/pro/agendas/${duplicatedAgenda._id}`);
    }
  }, [createdAgenda, duplicatedAgenda, navigate]);

  return (
    <>
      <Box
        borderRadius="12px"
        border="1px solid"
        borderColor="gray.200"
        my="2"
        p="3"
        maxHeight="calc(100vh - 360px)"
        shadow="sm"
      >
        <Button
          variant="primary"
          size="sm"
          rightIcon={<Icon as={RiAddCircleFill} fill="purple.200" />}
          isFullWidth={true}
          mb="3"
          isLoading={creatingAgenda}
          onClick={() => {
            createAgenda({
              title: `${DEFAULT_AGENDA_TITLE} (${currentAgendas.length + 1})`,
              description: "",
            });
          }}
        >
          Add Agenda
        </Button>
        <Tabs tabs={agendaTabs} variant="agenda" isFitted={true} />
      </Box>
      {/* Delete Agenda Modal */}
      <AlertDialog
        title="Delete Agenda"
        variant="delete"
        isOpen={agendaDeleteModalOpen}
        onClose={() => setAgendaDeleteModalOpen(false)}
        primaryButton={
          <Button
            variant="error"
            size="sm"
            isLoading={deletingAgenda}
            onClick={handleDeleteAgenda}
            ml={3}
          >
            Delete
          </Button>
        }
        secondaryButton={
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setAgendaDeleteModalOpen(false)}
            border="1px solid #D0D5DD"
            bg="white"
          >
            Cancel
          </Button>
        }
      >
        Are you sure you want to delete this agenda?
      </AlertDialog>
      {/* Duplicate Agenda Modal */}
      <AlertDialog
        title="Duplicate Agenda"
        headerIcon={duplicateIcon}
        isOpen={agendaDuplicateModalOpen}
        onClose={() => setAgendaDuplicateModalOpen(false)}
        primaryButton={
          <Button
            variant="primary"
            size="sm"
            isLoading={duplicatingAgenda}
            onClick={handleDuplicateAgenda}
            ml={3}
          >
            Duplicate
          </Button>
        }
        secondaryButton={
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setAgendaDuplicateModalOpen(false)}
          >
            Cancel
          </Button>
        }
      >
        Are you sure you want to duplicate this agenda?
      </AlertDialog>
    </>
  );
};

export default AgendaContainer;
