import { v4 as uuidv4  } from "uuid";
import { useMeeting } from "@hooks";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Flex,
  Text,
  Icon,
  Box,
  chakra,
} from "@chakra-ui/react";
import { Avatar } from "@components";
import { NavbarLink } from "@components/Navbar";
import { BiChevronDown, BiCreditCard } from "react-icons/bi";
import { RiUserLine } from "react-icons/ri";

const menuItems = [
  {
    id: uuidv4(),
    icon: RiUserLine,
    text: "Profile",
    href: "/account/edit-profile",
  },
  {
    id: uuidv4(),
    icon:BiCreditCard,
    text: "Billing",
    href: "/account/billing",
  },
];

const NavbarDropdown = ({ user }) => {
  const { firstName, lastName, avatar, color } = user;
  const { isMeetingActive } = useMeeting();

  const avatarSmall = (
    <Avatar
      avatar={avatar}
      size="sm"
      rounded={true}
      color={color}
      status={true}
    />
  );

  return (
    <>
      {avatarSmall}
      <Menu matchWidth isLazy lazyBehavior="unmount" placement={"bottom-end"}>
        <MenuButton
          as={IconButton}
          aria-label="Profile"
          icon={<Icon as={BiChevronDown} w={7} h={7} />}
          variant="link"
        />
        <MenuList
          bg="dark.500"
          border={0}
          p={0}
          mt={4}
          borderRadius="12px"
          boxShadow={
            "0px 10.5319px 21.0638px -5.26596px rgba(16, 24, 40, 0.18)"
          }
        >
          <MenuItem
            disabled={true}
            _hover={{ bg: "none" }}
            _active={{ bg: "none" }}
            _focus={{ bg: "none" }}
          >
            <Flex alignItems="center">
              {avatarSmall}
              <Text color="gray.25" fontWeight={600} fontSize="14px" ml={5}>
                {firstName} {lastName}
                <chakra.span
                  display="block"
                  color="dark.100"
                  fontWeight={400}
                  fontSize="12px"
                >
                  {isMeetingActive ? "online" : "offline"}
                </chakra.span>
              </Text>
            </Flex>
          </MenuItem>
          <Box borderRadius="0 0 10px 10px" bg="dark.400" p="5px 0">
            {menuItems.map((item) => (
              <NavbarLink key={item.id} icon={item.icon} text={item.text} href={item.href} />
            ))}
          </Box>
        </MenuList>
      </Menu>
    </>
  );
};

export default NavbarDropdown;
