import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHandleIconClick, useAuth } from "@hooks";
import {
  Box,
  Input,
  InputLeftElement,
  InputGroup,
  InputRightElement,
  CloseButton,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon,
  Flex,
  Heading,
  useToken,
} from "@chakra-ui/react";
import { IconPickerCategory } from "@components/IconPicker";
import { Stats } from "@components/IconPicker/Stats";
import { isZoomApp } from "@utils/helpers";
import searchIcon from "@assets/svg/icon-search.svg";
import { CgSmileMouthOpen } from "react-icons/cg";
import { BsGraphUp } from "react-icons/bs";

const IconPickerContainer = () => {
  const [dark500] = useToken("colors", ["dark.500"]);
  const icons = useSelector((state) => state.icons.data);
  const isLoading = useSelector((state) => state.icons.isLoading);
  const { broadcastIconClick, increaseIconUsage, setIconCategory } =
    useHandleIconClick(icons);
  const { profile } = useAuth();

  const meetingData = useSelector((state) => state.meeting.data?.meetingObject);
  const userId = useSelector((state) => state.profile.data._id);

  const [iconSearchQuery, setIconSearchQuery] = useState("");
  const [clickCount, setClickCount] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);

  const moodIcons = setIconCategory("mood");
  const statusIcons = setIconCategory("status");
  const vibeIcons = setIconCategory("vibe");

  const allIconsFiltered = useMemo(
    () =>
      icons?.all.filter((currentIcon) =>
        currentIcon.name.includes(iconSearchQuery.toLowerCase())
      ) || [],
    [iconSearchQuery, icons]
  );

  const searchTitle =
    !allIconsFiltered.length && iconSearchQuery.length
      ? `No search results for "${iconSearchQuery}"`
      : `Search "${iconSearchQuery}"`;

  const handleIconSearchInput = (event) => {
    setIconSearchQuery(event.target.value);
  };

  const handleIcon = (iconId) => {
    const { color } = profile;
    setClickCount(clickCount + 1);
    broadcastIconClick({
      iconId,
      color,
      meetingUuid: meetingData?.meetingInstances[0].meetingUuid,
      userId,
    });

    // TODO: This is not very accurate because what happens when one clicks different icons in the
    // debounce timeframe? The iconId will not be the true val, because it'll only rep the last clicked icon
    // we need something like managing the state of the currently clicked icons in that timeframe
    // [ {iconId: string, clickCount: number} ] - should be ideal thing sent to the backend
    increaseIconUsage({
      iconId,
      color,
      usageCount: clickCount,
      meetingUuid: meetingData?.meetingInstances[0].meetingUuid,
      userId,
      setClickCount,
    });
  };

  const handleTabChange = (index) => setTabIndex(index);

  return (
    <Box
      backgroundColor="dark.800"
      width="100%"
      height="300px"
      bottom="0"
      borderRadius="18px 18px 0 0"
      boxShadow={`0px -3px 1px ${dark500}`}
    >
      <Box padding="10px">
        {/* TODO: This is not a final fix. Once we know what the other tabs will show at the top will need to be refactored in a better way. */}
        {tabIndex === 0 ? (
          <InputGroup>
            <InputLeftElement children={<Image src={searchIcon} />} />
            <Input
              mb="0"
              value={iconSearchQuery}
              onChange={handleIconSearchInput}
              placeholder="Search for Icons"
              variant="iconPickerContainer"
            />
            {iconSearchQuery.length > 0 ? (
              <InputRightElement>
                <CloseButton
                  color="gray.400"
                  size="sm"
                  onClick={() => setIconSearchQuery("")}
                />
              </InputRightElement>
            ) : null}
          </InputGroup>
        ) : (
          isZoomApp ? (
            <Flex alignItems="center">
              <Icon as={BsGraphUp} color="blue.500" mr="1" />
              <Heading color="gray.400" fontSize="14px" fontWeight="600">
                Gamification Stats
              </Heading>
            </Flex>
          ) : null
        )}
      </Box>
      <Tabs
        orientation="vertical"
        variant="icons"
        isFitted={true}
        onChange={handleTabChange}
      >
        <TabPanels>
          <TabPanel
            overflowY="scroll"
            maxH="180px"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {iconSearchQuery.length > 0 ? (
              <IconPickerCategory
                title={searchTitle}
                icons={allIconsFiltered}
                divider={true}
                handleIcon={handleIcon}
                isLoading={isLoading}
              />
            ) : null}
            <IconPickerCategory
              title="Frequently Used"
              icons={icons?.favs}
              divider={true}
              handleIcon={handleIcon}
              isLoading={isLoading}
            />
            <IconPickerCategory
              title="Mood"
              icons={moodIcons}
              divider={true}
              handleIcon={handleIcon}
              isLoading={isLoading}
            />
            <IconPickerCategory
              title="Status"
              icons={statusIcons}
              divider={true}
              handleIcon={handleIcon}
              isLoading={isLoading}
            />
            <IconPickerCategory
              title="Vibe"
              icons={vibeIcons}
              divider={false}
              handleIcon={handleIcon}
              isLoading={isLoading}
            />
          </TabPanel>
          {isZoomApp ? (
            <TabPanel
              p="2"
              overflowY="scroll"
              height="200px"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Stats />
            </TabPanel>
          ) : null}
        </TabPanels>
        <TabList>
          <Tab>
            <Icon as={CgSmileMouthOpen} />
          </Tab>
          {isZoomApp ? (
            <Tab>
              <Icon as={BsGraphUp} />
            </Tab>
          ) : null}
        </TabList>
      </Tabs>
    </Box>
  );
};

export default IconPickerContainer;
