import { Box, Image, Flex } from "@chakra-ui/react";
import townhallLogo from "@assets/townhall.svg";

const LoginContainer = ({ children }) => {
  return (
    <>
      <Image
        src={townhallLogo}
        position={"absolute"}
        top={35}
        left={116}
        width={162}
        height={54}
        alt="Townhall"
      />
      <Flex
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        h="98%"
      >
        <Box
          w="sm"
          p="4px"
          borderRadius={"2xl"}
          bgGradient="linear-gradient(236deg, rgba(137, 18, 202, 0) 0%, #3E00EA 100%)"
        >
          <Box
            p="42px"
            borderRadius="2xl"
            bgGradient="linear-gradient(27.34deg, rgba(6, 9, 33, 0.53) -10.93%, #11163F 80.46%)"
          >
            {children}
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default LoginContainer;
