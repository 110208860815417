import { useSelector } from "react-redux";

const useProfile = () => {
  const profile = useSelector((state) => state.profile.data);
  const profileColor = useSelector((state) => state.profile.data.color);
  const platformParticipantUuid = useSelector((state) => state.profile.platformParticipantUuid);

  return { profile, profileColor, platformParticipantUuid };
};

export default useProfile;
