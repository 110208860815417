const useValidation = () => {
  const getInitialValues = (schema) => {
    return schema.initialValues;
  };

  const getValidation = (schema) => {
    return schema.validation;
  };

  return {
    getInitialValues,
    getValidation,
  };
};

export default useValidation;
