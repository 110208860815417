import React from "react";
import { useSelector } from "react-redux";
import { Box, Text, Flex, Icon, Button, DarkMode } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useSdkUtils } from "@hooks";
import { RiFlashlightFill } from "react-icons/ri";

const durationDisplayName = {
  day: "Daily",
  week: "Weekly",
  month: "Monthly",
  year: "Yearly",
};

const UpgradeButton = ({openUrl, isDisabled}) => (
  <Button
    size="large"
    variant="primary"
    mt={4}
    px={2}
    fontSize="sm"
    h="8"
    onClick={() => openUrl("https://www.townhall.pro/pricing")}
    isDisabled={isDisabled}
  >
    <Flex alignItems="center" gridGap="6px">
      <Icon as={RiFlashlightFill} w="16px" h="16px" />
      Upgrade
    </Flex>
  </Button>
);

const currentPlan = (
  <Box as="div" fontSize="9px" fontWeight="bold" color="green.400" borderColor="green.500" borderWidth="1px" px="4px" py="2px" borderRadius="4px">
    Current plan
  </Box>
);

const trialMessage = (
  <>
    <Text color="gray.400" fontSize="xs" mt="3">
      We hope you are enjoying your 14-day Free Trial of Townhall Pro!
    </Text>
    <Text color="gray.400" fontSize="xs" mt="3">
      Upgrade now so you do not lose access to premium features like E-Pulse and E-Report.
    </Text>
  </>
);

const BillingView = () => {
  const { openUrl } = useSdkUtils();
  const { subscription, licenseType } = useSelector((state) => state.profile.data);
  const { name, interval, subscriptionEnd } = subscription ?? {};

  const now = dayjs();
  const subscriptionEndDate = subscriptionEnd ? dayjs(subscriptionEnd) : null;
  const messagePart = subscriptionEndDate > now ? "renews automatically" : "ended";
  const isTrial = licenseType === "Trial";
  const isPaidSubscription = !isTrial && (name === "Pro Presenter" || name === "Pro Enterprise");
  const isUpgradeDisabled = name === "Pro Enterprise";

  return (
    <Flex width="full" flexDirection="column" gridGap="4px">
      {(name || isTrial) &&
        <Box bg="dark.700" borderRadius="18px" p={5}>
          <Flex alignItems="center" gridGap="16px">
            <Text as="h3" color="gray.200" fontWeight="bold" fontSize="16px">
              {isTrial ? "Pro Trial" : name}
            </Text>
            {currentPlan}
          </Flex>
          { isTrial && trialMessage}
          <UpgradeButton openUrl={openUrl} isDisabled={isUpgradeDisabled}/>
        </Box>
      }

      { isPaidSubscription && durationDisplayName[interval] && subscriptionEndDate &&
        <Box bg="dark.700" borderRadius="18px" p={5}>
          <Text as="div" fontSize="xs" fontWeight="bold" color="gray.200">
            Your{" "}
            <Text as="span" color="gray.400">
              {`${name} - ${durationDisplayName[interval]}`}
            </Text>
            {` subscription ${messagePart} on `}
            <Text color="gray.400" as="span">
              {subscriptionEndDate.format("MMM D, YYYY")}
            </Text>
          </Text>
        </Box>
      }

      { isPaidSubscription &&
        <Box bg="dark.700" borderRadius="18px" p={5}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text as="h4" fontSize="14px" color="gray.200" fontWeight="semibold">Billing Info</Text>
            <DarkMode>
              <Button variant="secondary" size="sm" fontSize="xs"
                onClick={() => openUrl("https://stripe.com/")}
              >
                Manage
              </Button>
            </DarkMode>
          </Flex>
          <Text fontSize="xs" color="gray.400">Manage your subscription</Text>
        </Box>
      }

      <Box bg="dark.700" borderRadius="18px" p={5}>
        <Text as="div" fontSize="xs" color="gray.400">Not sure which plan to choose?</Text>
        <Button variant="link" fontSize="sm" color="cyan.500" _active={{ color: "cyan.400" }}
          onClick={() => openUrl("https://www.townhall.pro/pricing")}
        >
          Compare Plans
        </Button>
      </Box>
    </Flex>
  )
}

export default BillingView;
