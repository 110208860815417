import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { activitiesApi } from "@store/api/activities";
import { meetingApi } from "@store/api/meeting";
import { agendaApi } from "@store/api/agenda";
import { activityTypes } from "src/constants/activities";

const initialState = {
  activities: [],
  liveActivity: {},
  liveTools: [],
  selectedActivity: {},
  options: [],
  response: [],
  userResponse: [],
  cachedType: "",
  cachedTitle: "",
  showResult: false,
  isLoading: true,
};

export const activitiesSlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    updateSelectedActivity: (state, action) => {
      const { activity } = action.payload;
      state.selectedActivity = activity;
    },
    updateLiveActivityTool: (state, action) => {
      const { activity } = action.payload;
      state.liveTools = state.liveTools.concat(activity);
    },
    updateLiveActivity: (state, action) => {
      const { activity } = action.payload;
      state.liveActivity = activity || {};
      state.showResult = activity.settings.showResult;
    },
    addActivityResponse: (state, { payload }) => {
      const { activity } = payload;
      activitiesSlice.caseReducers.updateActivityInList(state, activity);
    },
    updateParticipantMeetingEnd: (state, { payload }) => {
      const { activity } = payload;
      state.response = activity.response || [];
      state.options = activity.options;
      state.cachedType = activity.type;
      state.cachedTitle = activity.title;
      state.showResult = activity.settings.showResult;
      state.liveActivity = {};
    },
    addUserResponse: (state, { payload }) => {
      const { userResponse, } = payload;
      state.userResponse = userResponse;
    },
    updateActivityInList: (state, payload) => {
      const index = state.activities.findIndex(el => el._id === payload._id);
      state.activities[index] = payload;
    },
    endActivity: (state, { payload }) => {
      const activity = { ...payload };
      state.liveActivity = {};
      activitiesSlice.caseReducers.updateActivityInList(state, activity);
    },
    checkForLiveActivity: (state) => {
      const liveActivity = state.activities.filter(
        (activity) => activity.live && activity.type !== activityTypes.send
      );
      state.liveActivity = liveActivity[0] || {};
    },
    prepareStateForLiveActivity: (state) => {
      state.response = [];
      state.options = [];
      state.userResponse = [];
      state.showResult = false;
    },
    clearActivityState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      activitiesApi.endpoints.getActivities.matchFulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.activities = payload;
      }
    );
    builder.addMatcher(
      activitiesApi.endpoints.createActivity.matchFulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.activities = [payload.activity].concat(state.activities);
        state.selectedActivity = {};
      }
    );
    builder.addMatcher(
      activitiesApi.endpoints.updateActivity.matchFulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.selectedActivity = {};
        activitiesSlice.caseReducers.updateActivityInList(state, payload);
      }
    );
    builder.addMatcher(
      activitiesApi.endpoints.deleteActivity.matchFulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        const updatedActivity = state.activities.filter((activity) => activity._id !== payload._id)
        state.activities = updatedActivity;
        state.selectedActivity = {};
      }
    );
    builder.addMatcher(
      activitiesApi.endpoints.goLive.matchFulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        if (payload.type === activityTypes.send) {
          state.liveTools = state.liveTools = state.liveTools.concat(payload);
        } else {
          state.liveActivity = payload;
        }
        activitiesSlice.caseReducers.updateActivityInList(state, payload);
      }
    );
    builder.addMatcher(
      activitiesApi.endpoints.stopLive.matchFulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.liveActivity = {};
        activitiesSlice.caseReducers.updateActivityInList(state, payload);
      }
    );
    builder.addMatcher(
      activitiesApi.endpoints.respondToActivity.matchFulfilled,
      (state, { payload }) => {
        const { activity } = payload;
        state.isLoading = false;
        state.cachedType = activity.type;
        state.liveActivity.response = activity.response || [];
        state.response = activity.response;
      }
    );
    builder.addMatcher(
      ({ type }) => type.startsWith(activitiesApi.reducerPath) && type.endsWith("/pending"),
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      ({ type }) => type.startsWith(activitiesApi.reducerPath) && type.endsWith("/rejected"),
      (state) => {
        state.isLoading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        meetingApi.endpoints.checkExistingMeeting.matchFulfilled,
        meetingApi.endpoints.guestJoinMeeting.matchFulfilled
      ),
      (state, { payload }) => {
        state.liveActivity = payload.meetingInstances[0]?.agenda?.liveActivity || {};
        state.liveTools = payload.meetingInstances[0]?.agenda?.liveTools || [];
      }
    );
    builder.addMatcher(
      agendaApi.endpoints.stopLiveAgenda.matchFulfilled,
      (state) => {
        state.liveActivity = {};
        state.liveTools = [];
        state.activities = state.activities.map((activity) => {
          if (!activity.endTime) {
            activity.endTime = Date.now();
          }
          return activity;
        });
      }
    );
  },
});

const { reducer, actions } = activitiesSlice;

export const {
  updateLiveActivityTool,
  updateParticipantMeetingEnd,
  showResult,
  addUserResponse,
  addActivityResponse,
  updateLiveActivity,
  checkForLiveActivity,
  endActivity,
  updateSelectedActivity,
  prepareStateForLiveActivity,
} = actions;

export default reducer;
