import { Box, SimpleGrid } from "@chakra-ui/react";

const ParticipantsGridView = ({ renderParticipantType }) => (
  <Box
    p="6px 15px"
    height="calc(100vh - 490px)"
    overflowY="scroll"
    sx={{
      "&::-webkit-scrollbar": {
        display: "none",
      },
    }}
  >
    <SimpleGrid columns="3" spacing="7">
      {renderParticipantType()}
    </SimpleGrid>
  </Box>
);

export default ParticipantsGridView;
