import React, { useEffect, useCallback } from "react";
import { Text, Box, Icon, Flex, Image } from "@chakra-ui/react";
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { getActivityAnswers } from "@utils/helpers";
import { addUserResponse } from "@store/activitySlice";

const QuizResult = ({ setModalState }) => {
  const dispatch = useDispatch();
  const avatar = useSelector((state) => state.profile.data.avatar);
  const userResponse = useSelector((state) => state.activity?.userResponse);
  const response = useSelector((state) => state.activity.response);
  const options = useSelector((state) => state.activity.options);
  const showResult = useSelector((state) => state.activity.showResult);
  const profile = useSelector((state) => state.profile.data);

  const isAnswerCorrect = (userResponses = [], quizOptions = []) => {
    const correctOptions = quizOptions.filter((option) => option.correct);

    if (!userResponses.length || correctOptions.length !== userResponses.length) {
      return false
    }
    return correctOptions.every((option) => userResponses.includes(option._id));
  };

  const filterAnswerCallback = (answer, option) => answer.responseIds.includes(option._id);

  const getQuizAnswerLength = (response) => [...response].reduce((a, b) => a + b, 0);

  const isUserAnswer = (option) => userResponse ? userResponse.includes(option.id) : false;

  const responseNumberArray = [...response].map(answer => answer.responseIds.length);

  const totalAnswerLength = getQuizAnswerLength(responseNumberArray);
  const answerArray = getActivityAnswers(options, response, filterAnswerCallback, totalAnswerLength);

  const getUserResponse = useCallback((responses) => {
    const userProvidedResponse = responses.find((response) => response?.user?.userId === profile._id)
    return userProvidedResponse?.responseIds || [];
  }, [profile]);

  useEffect(() => {
    if (response.length > 0) {
      const currentUserResponse = getUserResponse(response);
      dispatch(addUserResponse({ userResponse: currentUserResponse }));
    }
  }, [response, dispatch, getUserResponse]);

  useEffect(() => {
    setModalState({
      otherProps: {
        isCorrect: isAnswerCorrect(userResponse, options)
      }
    });
  }, [options, showResult, setModalState, userResponse]);

  return (
    <Box background="gray.50" p={2} mt={2} mb={4} borderRadius={5}>
      {answerArray.map((option) => (
        <Box mb={2} key={option.id}>
          <Text fontWeight="600" fontSize="12px" color="dark.400" mb={1}>
            {option.label}
            {(isUserAnswer(option)) && (
              <Icon
                as={option.correct ? IoCheckmarkCircleSharp : IoCloseCircleSharp}
                color={option.correct ? "green.400" : "red.400"}
                w={3.5}
                h={3.5}
                marginLeft={1}
              />
            )}
          </Text>
          <Flex
            alignItems="center"
            m="5px 0"
          >
            <Flex
              alignItems="center" 
              width={option.value > 25 ? `${option.value}%` : `calc(${option.value}% + 25px)`}
            >
              <Box
                display="flex"
                alignItems="center"
                role="progressbar"
                aria-valuemax="100"
                aria-valuemin="0"
                aria-valuenow={option.value}
                width="100%"
                height={3}
                bg={option.correct ? "green.400" : (isUserAnswer(option) && option.correct === false ? "red.400" : "gray.300")}
                borderRadius="full"
                marginRight={1}
              />
              <Text 
                fontSize="10px" 
                fontWeight="800" 
                marginRight={1}
                color="gray.500"
              >
                {`${option.value}%`}
              </Text>
            </Flex>
            <Box width={4}>
              {isUserAnswer(option) && <Image src={avatar} borderRadius="full" width={4} />}
            </Box>
          </Flex>
        </Box>
      ))}
  </Box>

  )
}

export default QuizResult;
