import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/payments`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        const parsedToken = JSON.parse(token);
        headers.set("authorization", `Bearer ${parsedToken.accessToken}`);
      }
      return headers;
    },
    credentials: "include",
  }),
  endpoints: (builder) => {
    return {
      validate: builder.mutation({
        query: (body) => ({
          url: "/validateAccessCode",
          method: "PUT",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
    };
  },
});

export const { useValidateMutation } = paymentsApi;
