import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { editProfileSchema } from "@schema";
import { useValidation, useToastNotification } from "@hooks";
import { useUpdateProfileMutation } from "@store/api/auth";
import { Heading, VStack, Button, Grid } from "@chakra-ui/react";
import { AuthInput, Select } from "@components";
import { COUNTRY_LIST, USA_STATES } from "@constants";
import colors from "@utils/theme/colors";

const ProfileForm = () => {
  const profile = useSelector((state) => state.profile.data);
  const [updateProfile, { isLoading: isUpdatingProfile }] =
    useUpdateProfileMutation();
  const { getValidation } = useValidation();
  const { showSuccessToast, showErrorToast } = useToastNotification();
  const countryWithState = "United States of America";

  const handleSave = async (values) => {
    try {
      let newValues = {};

      for (const key in values) {
        if (key !== "_id") {
          newValues[key] = values[key];
        }
      }

      newValues["country"] =
        newValues.country === "Country" ? "" : newValues.country;
      newValues["state"] =
        newValues.country === countryWithState ? newValues.state : "";

      await updateProfile(newValues);
      showSuccessToast("Updated profile successfully");
    } catch (e) {
      showErrorToast("Failed to update profile");
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={profile || {}}
      validationSchema={getValidation(editProfileSchema)}
      onSubmit={handleSave}
    >
      {({ values, errors }) => {
        const isErrorFree = Object.keys(errors).length !== 0;
        const isUSA = values?.country === countryWithState;
        const stateArray = isUSA ? USA_STATES : [];

        return (
          <Form>
            <VStack
              spacing={3}
              my="5"
              alignItems="flex-start"
              height="calc(100vh - 390px)"
              overflowY="auto"
              pr="10px"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#1c2653",
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#393f6c",
                  borderRadius: "2.5px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#484d72",
                },
              }}
            >
              <AuthInput
                name="username"
                placeholder="Username"
                requireValidation={true}
                value={values?.username}
                helperText="Username must be 5 characters or less"
              />
              <AuthInput
                name="firstName"
                placeholder="First name"
                requireValidation={true}
                value={values?.firstName}
              />
              <AuthInput
                name="lastName"
                placeholder="Last name"
                requireValidation={true}
                value={values?.lastName}
              />
              <AuthInput
                name="organization"
                type="text"
                placeholder="Organization"
                value={values?.organization}
              />
              <AuthInput
                name="jobTitle"
                type="text"
                placeholder="Job Title"
                value={values?.jobTitle}
              />
              <Select
                name="country"
                value={values?.country || "United States of America"}
                variant="auth"
                placeholder="Country"
              >
                {COUNTRY_LIST.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item}
                      style={{ color: colors.dark[700] }}
                    >
                      {item}
                    </option>
                  );
                })}
              </Select>
              <Grid templateColumns="repeat(2, 1fr)" gap="6">
                <Select
                  name="state"
                  value={values?.state || ""}
                  variant="auth"
                  placeholder="State"
                  disabled={values?.country !== countryWithState}
                >
                  {stateArray.map((item, index) => {
                    return (
                      <option
                        key={index}
                        value={item}
                        style={{ color: colors.dark[700] }}
                      >
                        {item}
                      </option>
                    );
                  })}
                </Select>
                <AuthInput
                  name="city"
                  placeholder="City"
                  value={values?.city}
                />
              </Grid>
              <Heading as="h4" fontSize="18px" fontWeight="600" color="white">
                Socials
              </Heading>
              <AuthInput
                name="socials.linkedIn"
                placeholder="LinkedIn URL"
                value={values?.socials?.linkedIn}
              />
              <AuthInput
                name="socials.facebook"
                placeholder="Facebook URL"
                value={values?.socials?.facebook}
              />
            </VStack>
            <Button
              type="submit"
              variant="primary"
              isLoading={isUpdatingProfile}
              loadingText="Saving..."
              isDisabled={isErrorFree}
              isFullWidth={true}
            >
              Save
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ProfileForm;
