import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import useProfile from "./useProfile";

const useMeeting = () => {
  const [hostId, setHostId] = useState(null);
  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [guests, setGuests] = useState([]);
  const { profile, platformParticipantUuid } = useProfile();
  const [meetingObj, setMeetingObj] = useState({});
  const meeting = useSelector((state) => state.meeting.data);
  const isMeetingActive = meeting.inAMeeting;
  const platformMeetingHosts = meeting.meetingObject?.meetingInstances[0]?.platformMeetingHosts;
  const platformMeetingInstanceId = useSelector((state) => state.meeting.data.meetingObject?.meetingInstances?.[0].platformMeetingInstanceId);
  const platformMeetingId = useSelector((state) => state.meeting.data.meetingObject?.platformMeetingId);

  const coHostPlatformParticipantIds = useMemo(() => {
    if (!platformMeetingHosts?.coHosts) {
      return [];
    }
    return platformMeetingHosts?.coHosts.map((cohost) => cohost.platformParticipantUuid)
  }, [platformMeetingHosts?.coHosts]);

  const isHost = useCallback((id) => (hostId === id), [hostId]);

  const isCoHost = useMemo(
    () => coHostPlatformParticipantIds.includes(platformParticipantUuid),
    [coHostPlatformParticipantIds, platformParticipantUuid]
  );

  const isHostOrCoHost = useCallback((id) => isHost(id) || isCoHost, [isCoHost, isHost]);
  
  useEffect(() => {
    if (meeting.meetingObject) {
      const participants = meeting.meetingObject?.meetingInstances[0].participantsInMeetingOnTH;
      setMeetingObj(meeting.meetingObject)
      setMeetingParticipants(participants)
      setHostId(meeting?.meetingObject?.hostId);

      const guests = meeting.meetingObject?.meetingInstances[0].participantsInMeetingOnZoom;
      setGuests(guests);
    } else {
      setMeetingParticipants([profile]);
    }
  }, [meeting, profile]);

  return {
    hostId,
    isCoHost,
    isHost,
    isHostOrCoHost,
    meetingParticipants,
    guests,
    isMeetingActive,
    meetingObj,
    platformMeetingId,
    platformMeetingInstanceId,
  };
};

export default useMeeting;
