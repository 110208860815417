import {
  Text,
  Icon,
  IconButton,
  Image,
  List,
  ListItem,
} from "@chakra-ui/react";
import { Popover } from "@components";
import { FaRegQuestionCircle } from "react-icons/fa";
import proHelpStarsIcon from "@assets/pro-help-star.svg";
import proHelpListIcon from "@assets/pro-help-list-icon.svg";

const AgendaHelp = () => {
  const listItems = [
    'Click "New Agenda +" to create an agenda and "Add Activity+" to customize the meeting with Polls, Quizzes, etc.',
    "Schedule your event in Zoom and share the invitation with Townhall participants.",
    "5-10 minutes before your event, log into Zoom (as the Host), open Townhall PRO, connect an Agenda to your event, then Go Live!",
  ];

  const ListIcon = () => (
    <Image
      display="inline"
      marginInlineEnd="0.5rem"
      verticalAlign="baseline"
      src={proHelpListIcon}
    />
  );

  return (
    <Popover
      placement="top-start"
      trigger="click"
      button={
        <IconButton
          variant="ghostIconButton"
          icon={
            <Icon as={FaRegQuestionCircle} color="dark.300" w="1em" h="1em" />
          }
        />
      }
      heading={
        <>
          <Image src={proHelpStarsIcon} mr="2" />
          <Text>3 Easy Steps to PRO</Text>
        </>
      }
      body={
        <List spacing={2} fontSize="12px">
          {listItems.length > 0 &&
            listItems.map((item, i) => (
              <ListItem key={i}>
                <ListIcon as={ListIcon} />
                {item}
              </ListItem>
            ))}
        </List>
      }
    />
  );
};

export default AgendaHelp;
