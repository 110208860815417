import { Text, Flex } from "@chakra-ui/react";
import { Card } from "./Card";

const WebAppAuthState = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100%">
      <Flex
        flexDirection="column"
        alignItems="center"
        width="80%"
        zIndex="0"
        position="relative"
      >
        <Card alert={false}>
          <Text textAlign="center" textColor="gray.800" fontSize="sm">
            It seems that the link you used to access this page is not properly formatted.
            Please make sure you are using the correct link provided by your meeting host
          </Text>
        </Card>
      </Flex>
    </Flex>
  )
}

export default WebAppAuthState;
