import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import {
  MeetingContainer,
  IconViewer,
  AgendaHelp,
  ProTrialPopup,
  TourContainer,
} from "@components";

const ProContainer = ({ children }) => {
  const location = useLocation();
  const licenseType = useSelector((state) => state.profile.data.licenseType);
  const isPro = licenseType === "Pro";
  const isProAgendasView = location.pathname === "/pro/agendas";

  return (
    <MeetingContainer>
      <TourContainer>
        <Flex justifyContent="flex-end" position="relative">
          <Box
            left="2"
            position="absolute"
            height="100vh"
            width="20px"
            maxW="425px"
          >
            <IconViewer />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            height="100vh"
            width="275px"
            bg="gray.25"
            bgGradient={
              isProAgendasView ? "linear(blue.25 15%, gray.25 10%)" : null
            }
          >
            {children}
            <Box
              bg="gray.100"
              p="5px"
              h="50px"
              w="100%"
              position="absolute"
              bottom="70px"
            >
              <Flex justify="space-between" align="center">
                <AgendaHelp />
                {!isPro && <ProTrialPopup />}
              </Flex>
            </Box>
          </Box>
        </Flex>
      </TourContainer>
    </MeetingContainer>
  );
};

export default ProContainer;
