import { Box, Flex } from "@chakra-ui/react";
import { WebviewNavbar } from "@components/WebviewContainer";

const WebviewContainer = ({ children }) => {
  return (
    <Box height="100%">
      <WebviewNavbar />
      <Flex
        justifyContent="center"
        alignItems="center"
        bgColor="dark.800"
        width="100%"
        height="calc(100vh - 150px)"
        overflow="hidden"
      >
        {children}
      </Flex>
    </Box>
  );
};

export default WebviewContainer;
