import { Text } from "@chakra-ui/react";
import { EPulseContainer, EPulseChart } from "@components";

const EPulse = ({ agenda, live }) => {
  return (
    <EPulseContainer agenda={agenda}>
      {!live && !agenda.startTime && (
        <Text
          textAlign="center"
          color="gray.500"
          fontSize="12px"
          fontWeight="600"
          width="130px"
          position="absolute"
          left="30%"
          top="50%"
          transform="translateX(-50%, -50%)"
          zIndex="1"
        >
          Click "Go Live" to start E-Pulse
        </Text>
      )}
      <EPulseChart live={live} agenda={agenda} />
    </EPulseContainer>
  );
};

export default EPulse;
