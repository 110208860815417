import { Flex, Heading, Button, Icon, Center } from "@chakra-ui/react";
import { Avatar } from "@components";
import tourAvatar from "@assets/images/tour-avatar.jpg";
import { BsStars } from "react-icons/bs";

const TourTooltip = ({
  index,
  primaryProps,
  skipProps,
  step,
  isLastStep,
  tooltipProps,
}) => {
  const { title, content } = step;
  const buttonText = index === 0 ? "Start Tour" : "Next";
  const textAlign = isLastStep ? "center" : "inherit";

  return (
    <Flex
      p="3"
      bg="white"
      borderRadius="12px"
      width="300px"
      direction="column"
      {...tooltipProps}
    >
      <Flex>
        {!isLastStep ? (
          <Avatar size="sm" avatar={tourAvatar} rounded={true} mr="2" />
        ) : null}
        <Flex direction="column" textAlign={textAlign}>
          {isLastStep ? (
            <Center>
              <Icon as={BsStars} color="purple.300" boxSize="5" mb="1" />
            </Center>
          ) : null}
          <Heading fontSize="14px" fontWeight="700" color="dark.600" mb="1">
            {title}
          </Heading>
          {content}
        </Flex>
      </Flex>
      {isLastStep ? (
        <Flex justifyContent="center">
          <Button {...skipProps} size="sm" variant="primary" isFullWidth>
            Continue to FREE Trial
          </Button>
        </Flex>
      ) : (
        <Flex justifyContent="flex-end">
          <Button {...skipProps} size="sm" variant="secondary" mr="1">
            Skip
          </Button>
          <Button {...primaryProps} size="sm" variant="primary">
            {buttonText}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default TourTooltip;
