import { useCallback } from "react";
import { useToast } from "@chakra-ui/react";

const useToastNotification = () => {
  const toast = useToast();

  const generateToast = useCallback((message, type, position) => {
    toast({
      position,
      description: message,
      status: type,
    });
  }, [toast]);

  const showSuccessToast = (message) => generateToast(message, "success", "top");
  const showErrorToast = (message) => generateToast(message, "error", "top");
  
  return { showSuccessToast, showErrorToast }
}

export default useToastNotification;
