import { LoginContainer, RequireAuth } from "@components";
import { ProContainer } from "@components";
import { isZoomApp } from "@utils/helpers"

class ElementBuilder {
  constructor(config, licenseType) {
    this.component = <config.component />;
    this.builtComponent = <config.component />;
    this.config = config;
    this.licenseType = licenseType;
  }

  withAuthContainer() {
    if (this.config.container) {
      return this;
    }

    this.builtComponent = this.config.requiresAuthentication ? (
      <RequireAuth>{this.component}</RequireAuth>
    ) : (
      <LoginContainer>{this.component}</LoginContainer>
    );
    return this;
  }

  withProContainer() {
    if (this.config.isPro) {
      // TODO: create component to replace div for non pro users
      this.builtComponent = isZoomApp ? (
        <ProContainer>{this.component}</ProContainer>
      ) : (
        <div></div>
      );
    }
    return this;
  }

  withOwnContainer() {
    const Container = this.config.container;
    if (Container) {
      this.builtComponent = <Container>{this.component}</Container>;
    }
    return this;
  }

  build() {
    return this.builtComponent;
  }
}

export { ElementBuilder };
