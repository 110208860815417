import { Box, Image, Text, Flex, Circle, IconButton } from "@chakra-ui/react";
import { useMeeting } from "@hooks";
import { truncateName } from "@utils/helpers";
import hostIndicatorIcon from "@assets/host-indicator.svg";
import avatarDefaultIcon from "@assets/avatar-default.svg";
import avatarDefaultGrayIcon from "@assets/avatar-default-gray.svg";
import editProfileIcon from "@assets/svg/icon-edit.svg";

const Avatar = ({
  id,
  avatar,
  name,
  color,
  variant,
  onClick,
  onEdit,
  showBorder,
  size,
  edit,
  rounded,
  status,
  darkIcon,
  inactive,
  ...restProps
}) => {
  const { isHost, isMeetingActive } = useMeeting();

  let width,
    height,
    defaultAvatarWidth,
    defaultAvatarHeight,
    borderRadius,
    background,
    renderAvatar;

  const smallerSizedAvatarBg = avatar
    ? `url(${avatar}) no-repeat center/cover`
    : color;
  const largerSizedAvatarBg =
    avatar && name
      ? `linear-gradient(to bottom, rgba(255,255,255,0) 20%, rgba(0,0,0,0.5)), url(${avatar}) no-repeat center/cover`
      : avatar
      ? `url(${avatar}) no-repeat center/cover`
      : color;

  switch (size) {
    case "xs":
      width = "16px";
      height = "16px";
      borderRadius = "1px";
      background = smallerSizedAvatarBg;
      defaultAvatarWidth = "10px";
      defaultAvatarHeight = "10px";
      break;
    case "sm":
      width = "9";
      height = "9";
      borderRadius = "10px";
      background = smallerSizedAvatarBg;
      defaultAvatarWidth = "20px";
      defaultAvatarHeight = "20px";
      break;
    case "md":
      width = "48px";
      height = "48px";
      borderRadius = "12px";
      background = largerSizedAvatarBg;
      break;
    case "lg":
      width = "55px";
      height = "55px";
      borderRadius = "10px";
      background = largerSizedAvatarBg;
      defaultAvatarWidth = "27px";
      defaultAvatarHeight = "27px";
      break;
    case "xl":
      width = "80px";
      height = "80px";
      background = largerSizedAvatarBg;
      defaultAvatarWidth = "27px";
      defaultAvatarHeight = "27px";
      break;

    default:
      break;
  }

  switch (variant) {
    case "avatar":
      renderAvatar = (
        <Box
          position="relative"
          display="grid"
          {...(inactive && {
            filter: "grayscale(100%)",
            sx: {
              WebkitFilter: "grayscale(100%)",
            },
          })}
        >
          <Box
            position="relative"
            display="inline-block"
            _before={
              showBorder && {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "100%",
                borderRadius: borderRadius,
                boxShadow: `0 0 0 2px ${color}`,
                zIndex: 0,
              }
            }
          >
            <Box
              display="flex"
              position="relative"
              alignItems="center"
              m="2px"
              width={width}
              flexDirection="column"
              height={height}
              borderRadius={borderRadius}
              background={background}
              justifyContent={avatar ? "flex-end" : "center"}
              {...(rounded && { borderRadius: "full" })}
              {...(onClick && { cursor: "pointer" })}
              {...(onClick && { onClick: onClick })}
              {...restProps}
            >
              {isHost(id) && (
                <Image
                  src={hostIndicatorIcon}
                  position="absolute"
                  top="-9px"
                  left="-12px"
                />
              )}
              {!avatar && (
                <Image
                  src={darkIcon ? avatarDefaultGrayIcon : avatarDefaultIcon}
                  width={defaultAvatarWidth}
                  height={defaultAvatarHeight}
                  mb={name ? "4px" : "0px"}
                />
              )}
              {name && (
                <Text
                  fontSize="12px"
                  lineHeight={1}
                  color="white"
                  textAlign="center"
                  fontWeight="semibold"
                  width="100%"
                >
                  {truncateName(name)}
                </Text>
              )}
            </Box>
          </Box>
          {status && (
            <Circle
              border="1px solid white"
              size="2.5"
              bg={isMeetingActive ? "success" : "gray.300"}
              position="absolute"
              bottom="0"
              right="0"
            />
          )}
          {edit && (
            <IconButton
              style={{ padding: 5 }}
              size="xs"
              variant="edit"
              aria-label="Edit Profile"
              icon={<Image boxSize="15px" src={editProfileIcon} />}
              onClick={onEdit}
            />
          )}
        </Box>
      );
      break;

    case "list":
      renderAvatar = (
        <Flex
          justifyContent="center"
          alignItems="center"
          width="55px"
          height="24px"
          borderRadius="8px"
          position="relative"
          _before={
            showBorder && {
              content: "''",
              position: "absolute",
              width: "100%",
              height: "100%",
              borderRadius: borderRadius,
              boxShadow: `0 0 0 2px ${color}`,
              zIndex: 0,
            }
          }
          {...(onClick && { cursor: "pointer" })}
          {...(onClick && { onClick: onClick })}
          {...(inactive && {
            filter: "grayscale(100%)",
            "-webkit-filter": "grayscale(100%)",
          })}
        >
          {isHost(id) && (
            <Image
              src={hostIndicatorIcon}
              position="absolute"
              top="-9px"
              left="-12px"
            />
          )}
          {name && (
            <Text fontSize="12px" color="white" fontWeight="semibold">
              {truncateName(name)}
            </Text>
          )}
        </Flex>
      );
      break;

    default:
      break;
  }

  return renderAvatar;
};

Avatar.defaultProps = {
  variant: "avatar",
};

export default Avatar;
