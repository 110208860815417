import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    credentials: "include",
    baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/user`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        const parsedToken = JSON.parse(token);
        headers.set("authorization", `Bearer ${parsedToken.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      oAuthSignIn: builder.mutation({
        query: (body) => ({
          url: "/oauth-signin",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      fetchProfile: builder.query({
        query: () => "/profile/me",
        transformResponse: (response) => response.content.user,
      }),

      updateProfile: builder.mutation({
        query: (body) => ({
          url: "/edit-profile",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      updateAvatar: builder.mutation({
        query: (body) => ({
          url: "/update-avatar",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      updateColor: builder.mutation({
        query: (body) => ({
          url: "/update-color",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      signUpUser: builder.mutation({
        query: (body) => ({
          url: "/local-signup",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      loginUser: builder.mutation({
        query: (body) => ({
          url: "/local-signin",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      verifyEmail: builder.mutation({
        query: (body) => ({
          url: "/verify-email",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      resendVerifyEmail: builder.mutation({
        query: (body) => ({
          url: "/resend-verify-email",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      verifyOnboarding: builder.mutation({
        query: (body) => ({
          url: "/update-onboarding",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      updateIsFirstView: builder.mutation({
        query: (body) => ({
          url: "/update-first-view",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      resetPasswordEmail: builder.mutation({
        query: (body) => ({
          url: "/reset-password-email",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      resetPassword: builder.mutation({
        query: (body) => ({
          url: "/reset-password",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      updateOnboardingProcess: builder.mutation({
        query: (body) => ({
          url: "/update-onboarding-process",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),

      updateOnboarding: builder.mutation({
        query: (body) => ({
          url: "/update-user-onboarding",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
    };
  },
});

export const {
  useOAuthSignInMutation,
  useFetchProfileQuery,
  useFetchUserQuery,
  useUpdateProfileMutation,
  useUpdateAvatarMutation,
  useSignUpUserMutation,
  useLoginUserMutation,
  useVerifyEmailMutation,
  useResendVerifyEmailMutation,
  useVerifyOnboardingMutation,
  useUpdateIsFirstViewMutation,
  useResetPasswordEmailMutation,
  useResetPasswordMutation,
  useUpdateColorMutation,
  useUpdateOnboardingProcessMutation,
  useUpdateOnboardingMutation,
} = authApi;
