import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { getActivityAnswers } from "@utils/helpers";

const PollResult = () => {
  const response = useSelector((state) => state.activity.response);
  const options = useSelector((state) => state.activity.options);
  const userResponse = useSelector((state) => state.activity?.userResponse);

  const answerArray = getActivityAnswers(options, response, (response, option) => response.responseIds.includes(option._id), response.length);
  const isUserAnswer = (option) => userResponse ? userResponse.includes(option.id) : false;

  return (
    <Box background="gray.50" p={2} mt={2} mb={4} borderRadius={5}>
      {answerArray.map((option) => (
        <Box mb={2} key={option.id}>
          <Text fontWeight="600" fontSize="12px" color="dark.400" mb={1}>
            {option.label}
          </Text>
          <Flex
            alignItems="center"
            m="5px 0"
          >
            <Flex
              alignItems="center" 
              width={option.value > 25 ? `${option.value}%` : `calc(${option.value}% + 25px)`}
            >
              <Box
                display="flex"
                alignItems="center"
                role="progressbar"
                aria-valuemax="100"
                aria-valuemin="0"
                aria-valuenow={option.value}
                width="100%"
                height={3}
                bg={option.correct ? "green.400" : (isUserAnswer(option) ? "green.400" : "gray.300")}
                borderRadius="full"
                marginRight={1}
              />
              <Text 
                fontSize="10px" 
                fontWeight="800" 
                marginRight={1}
                color="gray.500"
              >
                {`${option.value}%`}
              </Text>
            </Flex>
          </Flex>
        </Box>
      ))}
    </Box>
  )
};

export default PollResult;
