import { createSlice } from "@reduxjs/toolkit";
import { agendaApi } from "@store/api/agenda";

const initialState = {
  agendas: [],
  pastAgendas: [],
  currentAgenda: {},
  isLoading: true,
  eReport: {},
};

const agendasSlice = createSlice({
  name: "agenda",
  initialState,
  reducers: {
    addAgendas: (state, action) => {
      const { agendas } = action.payload;
      state.agendas = agendas;
    },
    addPastAgendas: (state, action) => {
      const { pastAgendas } = action.payload;
      state.pastAgendas = pastAgendas;
    },
    updateAgendas: (state, action) => {
      const { _id } = action.payload;
      state.agendas = state.agendas.filter((agenda) => agenda._id !== _id);
    },
    updateCurrentAgenda: (state, action) => {
      const { agenda } = action.payload;
      state.currentAgenda = agenda;
    },
    updateIsAgendaLoading: (state, action) => {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
    stopCurrentAgenda: (state) => {
      state.currentAgenda.live = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      agendaApi.endpoints.fetchSingleAgenda.matchPending,
      (state, action) => {
        state.isLoading = true;
        state.currentAgenda = {};
      }
    );
    builder.addMatcher(
      agendaApi.endpoints.fetchSingleAgenda.matchFulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.currentAgenda = payload;
      }
    );
    builder.addMatcher(
      agendaApi.endpoints.fetchEngagementReport.matchFulfilled,
      (state, { payload }) => {
        state.eReport = payload;
      }
    );
  },
});

const { reducer, actions } = agendasSlice;

export const {
  addAgendas,
  addPastAgendas,
  updateAgendas,
  updateCurrentAgenda,
  updateIsAgendaLoading,
  stopCurrentAgenda,
} = actions;
export default reducer;
