import React from "react";
import { Textarea, Input, Image, Box, Text } from "@chakra-ui/react";
import {
  QuizOptions,
  QuizSettings,
  PollSettings,
  PollOptions,
  SendOptions,
  SendSettings,
  SendTextArea,
} from "@components/ActivityTypes";
import { activityTypes, activityTitles } from "@constants/activities";
import { activitySchema } from "@schema/activities";
import startPollIcon from "@assets/play-button.svg";
import startButtonDisabled from "@assets/play-button-disabled.svg";
import stopPollIcon from "@assets/stop-button.svg";

const activityPartsObject = {
  [activityTypes.quiz]: {
    options: QuizOptions,
    settings: QuizSettings,
    optionTooltipLabel: () => (
      <>
        <li>Minimum of 2 options</li>
        <li>Maximum of 5 options</li>
        <li>You must select at least one correct answer option</li>
      </>
    ),
    placeholder: "Ask a question",
    titleField: Textarea,
  },
  [activityTypes.poll]: {
    options: PollOptions,
    settings: PollSettings,
    optionTooltipLabel: () => (
      <>
        <li>Minimum of 2 options</li>
        <li>Maximum of 5 options</li>
      </>
    ),
    placeholder: "Ask a question",
    titleField: Input,
  },
  [activityTypes.send]: {
    options: SendOptions,
    settings: SendSettings,
    placeholder: "Type message to attendees here",
    titleField: SendTextArea,
  },
};

export const getActivityParts = (activityType) => ({
  ...activityPartsObject[activityType],
  titleField: activityPartsObject[activityType]?.titleField,
  schema: activitySchema(activityType).validation,
});

export const getLiveButtonProps = (
  activity,
  isAgendaLive,
  hasLiveActivity,
  startActivity,
  stopActivity,
  isGoingLive,
) => {

  const activityTitle = activityTitles[activity.type];
  if (isAgendaLive && !activity.live && !activity.endTime) {
    return {
      icon: (
        <Image
          src={(hasLiveActivity || isGoingLive) && activity.type !== activityTypes.send ? startButtonDisabled : startPollIcon}
          w={6}
          h={6}
        />
      ),
      disabled: (hasLiveActivity || isGoingLive) && activity.type !== activityTypes.send,
      "aria-label": hasLiveActivity && activity.type !== activityTypes.send
        ? "Live activity running"
        : `Start ${activityTitle}`,
      onClick: startActivity,
    };
  }

  if (isAgendaLive && activity.live && !activity.endTime && activity.type !== activityTypes.send) {
    return {
      icon: <Image src={stopPollIcon} w={6} h={6} />,
      disabled: false,
      "aria-label": `End ${activityTitle}`,
      onClick: stopActivity,
    };
  }

  if (isAgendaLive && activity.live && !activity.endTime && activity.type === activityTypes.send) {
    return {
      icon: (
        <Box 
          background="green.500" 
          borderRadius="full"
          p="2px 8px"
          fontWeight="normal"
        >
          <Text color="white" fontSize="10px">Running</Text>
        </Box>),
      disabled: true,
      onClick: () => {},
    };
  }

  return {
    icon: <Image src={startButtonDisabled} w={6} h={6} />,
    disabled: true,
    "aria-label": activity.endTime
      ? `${activityTitle} completed`
      : `Click Play to start ${activityTitle}`,
  };
};
