import { Box, Flex, Image, Text } from "@chakra-ui/react";
import proParticipantsIcon from "@assets/svg/icon-pro-participants.svg";

const ProUsersCount = ({ count, dark, ...rest }) => {
  return (
    <Flex alignItems="center" {...rest}>
      <Box rounded="md" bg={dark ? "blue.25" : "white"} p="2" mr="1">
        <Image boxSize="4" src={proParticipantsIcon} />
      </Box>
      <Text fontSize="md">{count}</Text>
    </Flex>
  );
};

export default ProUsersCount;
