import { useSelector } from "react-redux";
import { Text, VStack } from "@chakra-ui/react";
import { ProCard, ProView } from "@components";
import { TRIAL_DAYS, LICENSE_TYPES } from "@constants";
import { useSdkUtils } from "@hooks";
import { formattedDate, daysRemaining } from "@utils/helpers";

const Pro = () => {
  const profile = useSelector((state) => state.profile.data);
  const { openUrl } = useSdkUtils();
  const { licenseType, trialPeriodStartDate } = profile;

  const trialStartDate = formattedDate(trialPeriodStartDate, "YYYY-MM-DD");
  const now = formattedDate(new Date(), "YYYY-MM-DD");
  const trialRemainingDays = daysRemaining(TRIAL_DAYS, now, trialStartDate);
  const trialEnded = trialRemainingDays <= 0;

  if (trialEnded && licenseType !== LICENSE_TYPES.Dev) {
    return (
      <ProCard
        heading="Trial Expired"
        text={[
          <VStack spacing="3" mb="3">
            ,
            <Text fontSize="12px">
              The trial period has expired, but we hope you join Townhall!
            </Text>
            ,
            <Text fontSize="12px">
              Make sure to check out our Strategic Partner program to earn a 90%
              discount!
            </Text>
            ,
            <Text fontSize="12px">
              We really appreciate your time and welcome any feedback. Thank
              you!
            </Text>
            ,
          </VStack>,
        ]}
        primaryAction={() => openUrl("https://www.townhall.pro/pricing")}
        secondaryAction={() => openUrl("https://www.townhall.pro/")}
      />
    );
  }

  return <ProView />;
};

export default Pro;
