import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const agendaApi = createApi({
  reducerPath: "agendaApi",
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        const parsedToken = JSON.parse(token);
        headers.set("authorization", `Bearer ${parsedToken.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createNewAgenda: builder.mutation({
        query: (agenda) => ({
          url: "/agenda",
          method: "POST",
          body: agenda,
        }),
        transformResponse: (response) => response.content,
      }),
      duplicateAgenda: builder.mutation({
        query: (agendaId) => ({
          url: `/agenda/duplicate/${agendaId}`,
          method: "POST",
        }),
        transformResponse: (response) => response.content,
      }),
      fetchAgendas: builder.query({
        query: () => ({
          url: "/agenda",
          method: "GET",
        }),
        transformResponse: (response) => response.content,
      }),
      deleteAgenda: builder.mutation({
        query: (agendaId) => ({
          url: `/agenda/${agendaId}`,
          method: "DELETE",
        }),
        transformResponse: (response) => response.content,
      }),
      fetchSingleAgenda: builder.query({
        query: (id) => ({
          url: `/agenda/${id}`,
          method: "GET",
        }),
        transformResponse: (response) => response.content,
      }),
      updateSingleAgenda: builder.mutation({
        query: ({ agendaId, meetingData: body }) => ({
          url: `/agenda/${agendaId}`,
          method: "PUT",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
      goLiveAgenda: builder.mutation({
        query: ({ agendaId, meetingInstanceId }) => ({
          url: `/agenda/goLive/${agendaId}`,
          method: "PUT",
          body: { meetingInstanceId }
        }),
        transformResponse: (response) => response.content,
      }),
      stopLiveAgenda: builder.mutation({
        query: (agendaId) => ({
          url: `/agenda/stopLive/${agendaId}`,
          method: "PUT",
        }),
        transformResponse: (response) => response.content,
      }),
      fetchEngagementReport: builder.query({
        query: (agendaId) => ({
          url: `/agenda/report/${agendaId}`,
          method: "GET",
        }),
        transformResponse: (response) => response.content,
      }),
    };
  },
});

export const {
  useCreateNewAgendaMutation,
  useDuplicateAgendaMutation,
  useDeleteAgendaMutation,
  useFetchAgendasQuery,
  useFetchSingleAgendaQuery,
  useUpdateSingleAgendaMutation,
  useGoLiveAgendaMutation,
  useStopLiveAgendaMutation,
  useFetchEngagementReportQuery,
} = agendaApi;
