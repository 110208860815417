import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const activitiesApi = createApi({
  reducerPath: "activitiesApi",
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/activities`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        const parsedToken = JSON.parse(token);
        headers.set("authorization", `Bearer ${parsedToken.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      createActivity: builder.mutation({
        query: (body) => ({
          url: `/`,
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
      updateActivity: builder.mutation({
        query: ({ activityId, body }) => ({
          url: `/${activityId}`,
          method: "PUT",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
      deleteActivity: builder.mutation({
        query: (activityId) => ({
          url: `/${activityId}`,
          method: "DELETE",
        }),
        transformResponse: (response) => response.content,
      }),
      getActivities: builder.query({
        query: (query) => ({
          url: `/`,
          method: "GET",
          params: query,
        }),
        transformResponse: (response) => [...response.content].reverse(),
      }),
      goLive: builder.mutation({
        query: (id) => ({
          url: `/${id}/live`,
          method: "PUT",
        }),
        transformResponse: (response) => response.content,
      }),
      stopLive: builder.mutation({
        query: (id) => ({
          url: `/${id}/stop`,
          method: "PUT",
        }),
        transformResponse: (response) => response.content,
      }),
      respondToActivity: builder.mutation({
        query: ({ id, body}) => ({
          url: `/${id}/respond`,
          method: "PUT",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
    };
  },
});

export const {
  useRespondToActivityMutation,
  useGetActivitiesQuery,
  useGoLiveMutation,
  useStopLiveMutation,
  useCreateActivityMutation,
  useUpdateActivityMutation,
  useDeleteActivityMutation,
} = activitiesApi;
