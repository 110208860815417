import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Button, Heading, Image, VStack } from "@chakra-ui/react";
import { useValidateMutation } from "@store/api/payments";
import { useUpdateOnboardingProcessMutation } from "@store/api/auth";
import { useToastNotification } from "@hooks";
import { Modal, ProStartTrialStepOne, ProStartTrialStepTwo } from "@components";
import { TRIAL_CODE } from "@constants";
import { isZoomApp } from "@utils/helpers";
import freeTrialIcon from "@assets/svg/icon-free-trial.svg";
import startTrialIcon from "@assets/svg/icon-trial-stars.svg";

const STEPS = {
  One: 'one',
  Two: 'two'
}

const ProStartTrialModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const profile = useSelector((state) => state.profile.data);
  const navigate = useNavigate();
  const [startTrial, { isLoading: isActivatingTrial }] = useValidateMutation();
  const [updateOnboardingProcess] = useUpdateOnboardingProcessMutation();
  const { showSuccessToast, showErrorToast } = useToastNotification();
  const [formFields, setFormFields] = useState({
    // useCase: [],
    meetingFrequency: "",
    companySize: "",
  });

  useEffect(() => {
    if (!profile.isOnboarded) {
      updateOnboardingProcess({
        email: profile.email,
        step: STEPS.One
      });
    }
  }, []);

  const handleClick = () => {
    if (step === 2) {
      handleStartTrial();
    }

    setStep(2);
  };

  const handleStartTrial = useCallback(async () => {
    try {
      const trialDetails = await startTrial({ accessCode: TRIAL_CODE }).unwrap();
      onClose();
      await updateOnboardingProcess({
        ...formFields,
        useCase: formFields.useCase ? formFields.useCase.join(';') : '',
        email: profile.email,
        step: STEPS.Two,
        trialStartDate: trialDetails.trialPeriodStartDate,
        //TODO: onboardingComplete value would change to time onboarding was completed when the onboarding workflow os completed
        onboardingComplete: trialDetails.trialPeriodStartDate
      });
      showSuccessToast("Trial activated successfully");
    } catch (error) {
      console.log(error);
      showErrorToast(
        "There was an error activating your free trial. Please try again."
      );
    }
  }, [showSuccessToast, showErrorToast, startTrial, updateOnboardingProcess, profile.email, onClose]);

  const handleCloseTrial = useCallback(() => {
    onClose();
    if (isZoomApp) {
      navigate('/')
    }
  }, [onClose, navigate]);

  const isFormFilled = (formObject) => {
    return (
      Object.keys(formObject).length > 0 &&
      Object.values(formObject).every((value) => value !== "")
    );
  };

  const isDisabled = step === 2 && !isFormFilled(formFields);

  const NavColor = ({ color, ...rest }) => (
    <Box borderRadius="full" bg={color} w="20px" h="0.5" {...rest} />
  );

  return (
    <Modal
      size="xs"
      isOpen={isOpen}
      onClose={handleCloseTrial}
      closeOnOverlayClick={false}
      showCloseButton
    >
      <VStack spacing="4" align="stretch">
        <Flex justifyContent="space-between" w="100%">
          <Image src={freeTrialIcon} />
          <Flex alignItems="center" pt={8}>
            {step === 1 ? (
              <>
                <NavColor mr="2" color="yellow.500" />
                <NavColor color="gray.300" />
              </>
            ) : (
              <>
                <NavColor mr="2" color="gray.300" />
                <NavColor color="yellow.500" />
              </>
            )}
          </Flex>
        </Flex>
        <Heading color="dark.600" fontWeight="700" fontSize="14px">
          Start Your 14 Day FREE Trial
        </Heading>
        {step === 1 ? (
          <ProStartTrialStepOne />
        ) : (
          <ProStartTrialStepTwo
            formFields={formFields}
            setFormFields={setFormFields}
          />
        )}
        <Button
          variant="primary"
          w="full"
          size="sm"
          isDisabled={isDisabled}
          isLoading={isActivatingTrial}
          loadingText="Activating"
          onClick={handleClick}
          {...(step === 2 && { leftIcon: <Image src={startTrialIcon} /> })}
        >
          {step === 1 ? "Continue" : "Let's Go!"}
        </Button>
      </VStack>
    </Modal>
  );
};

export default ProStartTrialModal;
