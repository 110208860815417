import { sendZoomRequest } from "@sdk/zoom";
import {
  emitOnMyReaction,
  emitOnMyActiveSpeakerChange,
  emitOnMyMediaChange,
  emitOnUserRoleChange,
} from "@socket";

export const handleOnAuthorized = async (event, data) => {
  const { code, state } = event;
  const { onSuccessCallback, onErrorCallback } = data;

  try {
    await sendZoomRequest("POST", '/user/client/onauthorized', {
      code,
      state,
      href: window.location.href,
    });
    if (onSuccessCallback) {
      onSuccessCallback();
    }
  } catch (error) {
    if (onErrorCallback) {
      onErrorCallback();
    }
    console.error(error);
  }
};

export const handleOnMyReaction = (event, data) => {
  const { userId, meetingInstanceId } = data;
  emitOnMyReaction({
    userId,
    meetingInstanceId: meetingInstanceId,
  });
};

export const handleOnMyActiveSpeakerChange = (event, data) => {
  const { userId, meetingInstanceId } = data;
  emitOnMyActiveSpeakerChange({
    userId,
    status: event.status,
    meetingInstanceId: meetingInstanceId,
  });
};

export const handleOnMyMediaChange = (event, data) => {
  const { userId, meetingInstanceId } = data;
  emitOnMyMediaChange({
    userId,
    audioOn: event.media.audio.state,
    videoOn: event.media.video.state,
    meetingInstanceId: meetingInstanceId,
  });
}

export const handleOnMyUserContextChange = (event, data) => {
  const { onSuccessCallback, userMeetingInfo } = data;
  emitOnUserRoleChange({ ...userMeetingInfo, newRole: event.role});
  if (onSuccessCallback) {
    onSuccessCallback(event);
  }
};

export const handleOnRunningContextChange = (event, data) => { 
  const { onSuccessCallback } = data;
  if (onSuccessCallback) {
    onSuccessCallback();
  }
}