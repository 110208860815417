import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSdkUtils } from "@hooks";
import { formattedDate, daysRemaining } from "@utils/helpers";
import { TRIAL_DAYS, DAYS } from "@constants";
import { Text, Button, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import { Popover, ProTrialPopupButton } from "@components";

const ProTrialPopup = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const profile = useSelector((state) => state.profile.data);
  const { openUrl } = useSdkUtils();
  const { licenseType, trialPeriodStartDate } = profile;
  const trialDate = formattedDate(trialPeriodStartDate, "YYYY-MM-DD");
  const now = formattedDate(new Date(), "YYYY-MM-DD");
  const trialRemainingDays = daysRemaining(TRIAL_DAYS, now, trialDate);
  const showZeroDays = trialRemainingDays < 1;

  useEffect(() => {
    const isTrialLicense = licenseType === "Trial";

    if (!isTrialLicense) {
      return;
    }

    const isShowModalDay = DAYS.includes(trialRemainingDays);
    const canShowModal =
      localStorage.getItem("trialPeriodDays") !== trialRemainingDays.toString();

    if (isTrialLicense && isShowModalDay && canShowModal) {
      onToggle();
      localStorage.setItem("trialPeriodDays", trialRemainingDays);
    }
  }, [licenseType, trialRemainingDays]);

  return trialPeriodStartDate ? (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-end"
      trigger="click"
      button={
        <ProTrialPopupButton
          maxDays={TRIAL_DAYS}
          daysRemaining={trialRemainingDays}
          onClose={onClose}
          onToggle={onToggle}
        />
      }
      heading={`${showZeroDays ? 0 : trialRemainingDays} Days Remaining ✨`}
      body={
        <>
          <VStack spacing="3" alignItems="flex-start" mb="3">
            <Text fontSize="12px">
              We hope the E-Pulse is giving you excellent real-time feedback on
              audience engagement and the E-Report is providing rich attendee
              analytics!
            </Text>
            <Text fontSize="12px">
              Make sure to check out our Strategic Partner program to earn a 90%
              discount!
            </Text>
          </VStack>
          <HStack>
            <Button size="sm" isFullWidth onClick={onClose}>
              Got it!
            </Button>
            <Button
              variant="primary"
              size="sm"
              isFullWidth
              onClick={() =>
                openUrl("https://www.townhall.pro/pricing")
              }
            >
              Join Pro ✨
            </Button>
          </HStack>
        </>
      }
    />
  ) : null;
};

export default ProTrialPopup;
