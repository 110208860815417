import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const iconApi = createApi({
  reducerPath: "iconApi",
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/icon`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        const parsedToken = JSON.parse(token);
        headers.set("authorization", `Bearer ${parsedToken.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      getAllIcons: builder.query({ query: () => "/all" }),
    };
  },
});

export const { useGetAllIconsQuery } = iconApi;
