import { Box, Text, useToken } from "@chakra-ui/react";
import proWelcomeBg from "@assets/images/pro-welcome-bg.png";

const ProWelcomeCard = () => {
  const [blue25] = useToken("colors", ["blue.25"]);

  return (
    <Box
      borderRadius="12px"
      border="1px solid"
      borderColor="blue.300"
      p="3"
      mt="3"
      w="100%"
      h="146px"
      bg={`url(${proWelcomeBg}) ${blue25} no-repeat right bottom`}
    >
      <Text fontSize="medium" fontWeight="600">
        Welcome to Townhall PRO ✨
      </Text>
      <Text fontSize="xs" color="gray.600">
        Add an Agenda for an active or future event.
      </Text>
    </Box>
  );
};

export default ProWelcomeCard;
