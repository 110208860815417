import React from 'react';
import {
  Button,
  Flex,
  Text,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  useDisclosure,
} from "@chakra-ui/react";

const UserOnboarding = ({ 
  isOpen,
  placement,
  onClick,
  title,
  textContent,
  step,
  buttonText = "Next",
  children
}) => {
  const { onClose } = useDisclosure();

  return (
    <Popover
      autoFocus={false}
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement={placement || "top" }
      closeOnBlur={false}
      variant="onboarding"
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <div style={{ margin: "10px" }}>
          <h4>{title}</h4>
          <div style={{ marginTop: "5px", marginBottom: "10px" }}>
            {textContent}
          </div>
          <Flex
            justifyContent="space-between"
          >
            <Text as="span" fontSize="10px" p={2} bg="purple.600" borderRadius="6px">{step}</Text>
            <Button zIndex={10000} variant="secondary" size="sm" onClick={onClick}>{buttonText}</Button>
          </Flex>
        </div>
      </PopoverContent>
    </Popover>
  )
};

export default UserOnboarding;
