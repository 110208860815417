import { Link as ReactLink } from "react-router-dom";
import { Box, Image, Flex, Link, Heading, Text } from "@chakra-ui/react";
import { PrimaryButton } from "@components";

const StepCard = ({ image, heading, text, handleClick, index, children }) => (
  <>
    <Image src={image} alt={heading} marginBottom={10} />
    <Box
      p="42px"
      borderRadius="2xl"
      bgGradient="linear-gradient(191.56deg, rgba(42, 47, 87, 0.5) 8.49%, rgba(42, 47, 87, 0) 110.18%);"
      width={308}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        direction="column"
        textAlign="center"
      >
        <Heading as="h1" variant="authPageH1">{heading}</Heading>
        <Text variant="authPageText" pt={3} pb={4}>{text}</Text>
        {children}
        <PrimaryButton isFullWidth={true} onClick={(e) => handleClick(e)}>
          {index === 2 ? "Home" : "Next"}
        </PrimaryButton>
        {index !== 2 && (
          <Text variant="authPageText">
            <Link as={ReactLink} to="/" color="gray.300">
              Skip to Home
            </Link>
          </Text>
        )}
      </Flex>
    </Box>
  </>
);

export default StepCard;
