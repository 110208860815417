import { Flex, Progress } from "@chakra-ui/react";

const FullPageLoader = () => (
  <Flex justifyContent="center" alignItems="center" height="100%">
    <Flex
      flexDirection="column"
      alignItems="center"
      width="80%"
      zIndex="0"
      position="relative"
    >
      <Progress
        width="15%"
        bgGradient="linear-gradient(to left, #8912CA, #3E00EA)"
        size="xs"
        borderRadius="50px"
        isIndeterminate
      />
    </Flex>
  </Flex>
);

export default FullPageLoader;
