import { Link } from "react-router-dom";
import { Box, Image, Flex, useMediaQuery, Button, Text, useDisclosure } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth, useSidebar, useMeeting } from "@hooks";
import {
  formattedDate,
  daysRemaining,
  isGuestWebUser,
  clearMeetingInfo,
  isZoomApp,
  generateUrlWithQueryParams,
} from "@utils/helpers";
import { clearMeetingState } from "@store/meetingSlice";
import { clearProfileState } from "@store/profileSlice";
import { TRIAL_DAYS } from "@constants";
import { emitRemoveWebUserFromMeeting, disconnectSocket } from "@socket";
import { NavbarDropdown, Modal } from "@components";
import townhallLogoOnly from "@assets/townhall-logo-only.svg";
import townhallText from "@assets/townhall_text.svg";
import proIcon from "@assets/trophy.svg";
import proIconActive from "@assets/pro-button.svg";

const Navbar = () => {
  const [isMobile] = useMediaQuery("(max-width: 375px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { profile } = useAuth();
  const { isMeetingActive, platformMeetingId, platformMeetingInstanceId } =
    useMeeting();
  const { licenseType, trialPeriodStartDate } = profile;
  const { isProView } = useSidebar();
  const liveAgenda = useSelector(
    (state) => state.meeting.data.meetingObject?.meetingInstances[0]?.agenda
  );
  const meetingHost = useSelector((state) => state.meeting.data.meetingHost);

  const basicUser = licenseType === "Basic";
  const trialUser = licenseType === "Trial";
  const trialStartDate = formattedDate(trialPeriodStartDate, "YYYY-MM-DD");
  const now = formattedDate(new Date(), "YYYY-MM-DD");
  const trialRemainingDays = daysRemaining(TRIAL_DAYS, now, trialStartDate);
  const trialEnded = trialRemainingDays <= 0;

  const homeUrl = isZoomApp
    ? "/"
    : isMeetingActive
    ? generateUrlWithQueryParams("/", {
        platformMeetingId,
        platformMeetingInstanceId,
      })
    : "/";

  const leaveMeeting = () => {
    clearMeetingInfo();
    emitRemoveWebUserFromMeeting({
      platformMeetingInstanceId,
      userId: profile?._id,
    });
    dispatch(clearProfileState());
    dispatch(clearMeetingState());
    disconnectSocket();
  };

  const handleLeaveMeeting = () => {
    onOpen();
  }

  const linkPath = () => {
    let link = "/pro/agendas";

    if (basicUser || (trialUser && trialEnded)) {
      link = "/pro";
    } else if (liveAgenda && meetingHost?._id === profile._id) {
      link = `/pro/agendas/${liveAgenda._id}`;
    }

    return link;
  };

  const setLink = linkPath();

  const pro = () =>
    process.env.REACT_APP_NAV_ENV
      ? `PRO - ${process.env.REACT_APP_NAV_ENV}`
      : "PRO";

  return (
    <Box
      bgColor="dark.800"
      padding="0.5rem"
      boxShadow="0px 5px 140px rgba(0, 12, 59, 0.2)"
      position="static"
    >
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Leave Meeting"
        size="xs"
        showCloseButton
        heading="Leave Meeting?"
      >
        <>
          <Text color="gray.900" fontSize="14px" mb={4} >
            Are you sure you want to leave this meeting?
          </Text>
          <Flex  width="full">
            <Button size="sm" onClick={onClose} flex={1}>
              Cancel
            </Button>
            <Button variant="error" size="sm" onClick={leaveMeeting} ml={3} flex={1}>
              Leave
            </Button>
          </Flex>
        </>
      </Modal>
      <Flex maxWidth="9xl" justifyContent="space-between" alignItems="center">
        <Link to={homeUrl}>
          <Flex>
            <Image
              src={townhallLogoOnly}
              alt="Townhall"
              width={isMobile ? 12 : 14}
            />
            <Image
              src={townhallText}
              alt="Townhall"
              display={isMobile && "none"}
            />
          </Flex>
        </Link>
        {isGuestWebUser(profile) && isMeetingActive ? (
          <Button variant="error" mr={3} onClick={handleLeaveMeeting}>
            Leave
          </Button>
        ) : null}
        {profile && !isGuestWebUser(profile) ? (
          <Flex alignItems="center" justify="space-around" width="30">
            <Link to={isProView ? "/" : setLink}>
              <Button
                variant="primary"
                mr={6}
                leftIcon={<Image src={isProView ? proIconActive : proIcon} />}
              >
                {isProView ? "Home" : pro()}
              </Button>
            </Link>
            <NavbarDropdown user={profile} />
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};

export default Navbar;
