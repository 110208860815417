import { useSelector } from "react-redux";
import { useMeeting, useEngagement } from "@hooks";
import { displayEscore } from "@utils/helpers";
import { StatIndicator } from ".";
import pointsIcon from "@assets/svg/icon-points.svg";
import coinsIcon from "@assets/svg/icon-coin.svg";
import eScoreIcon from "@assets/svg/icon-escore.svg";

const Stats = () => {
  const eScore = useSelector((state) => state.engagement.eScore);

  const { isMeetingActive } = useMeeting();
  const { points, coins } = useEngagement();

  const stats = [
    {
      icon: eScoreIcon,
      text: "E-Score:",
      value: displayEscore(isMeetingActive, eScore),
    },
    {
      icon: pointsIcon,
      text: "Points:",
      value: points?.toLocaleString(),
    },
    {
      icon: coinsIcon,
      text: "Coins:",
      value: coins?.toLocaleString(),
    },
  ];

  return stats.map((stat, index) => (
    <StatIndicator
      key={`icon-picker-stat-indicator-${index}`}
      icon={stat.icon}
      text={stat.text}
      value={stat.value}
    />
  ));
};

export default Stats;
