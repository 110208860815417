import { Flex, Heading, Text, Image } from "@chakra-ui/react";
import ePulseIcon from "@assets/svg/icon-trial-epulse.svg";
import eReportIcon from "@assets/svg/icon-trial-ereport.svg";
import activitiesIcon from "@assets/svg/icon-trial-activities.svg";

const ProStartTrialStepOne = () => {
  const listItems = [
    {
      icon: ePulseIcon,
      heading: "E-Pulse",
      text: "Instant and continuous feedback on audience engagement",
    },
    {
      icon: eReportIcon,
      heading: "E-Report",
      text: "Post meeting analytics on attendee participation",
    },
    {
      icon: activitiesIcon,
      heading: "Activities",
      text: "Poll, Quiz, and more to boost engagement in seconds",
    },
  ];

  return (
    <>
      <Text color="dark.300" fontSize="12px">
        Host features include:
      </Text>
      {listItems.map((item, index) => (
        <Flex alignItems="flex-start" key={`list-item-${index}`}>
          <Image src={item.icon} mr="3" />
          <Flex flexDirection="column">
            <Heading fontSize="12px" fontWeight="600" color="dark.600">
              {item.heading}
            </Heading>
            <Text color="dark.300" fontSize="12px">
              {item.text}
            </Text>
          </Flex>
        </Flex>
      ))}
    </>
  );
};

export default ProStartTrialStepOne;
