import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Image, Fade } from "@chakra-ui/react";
import { SidebarToggle, Participants } from "@components";
import { useSidebar } from "@hooks";
import { isZoomApp } from "@utils/helpers";
import proIcon from "@assets/trophy.svg";
import profileIcon from "@assets/profile.svg";

const SidebarClosed = ({
  tooltipCounter,
  incrementTooltipCounter,
  isFirstView,
  participants,
  guests,
  hostId
}) => {
  const [showItems, setShowItems] = useState(false);
  const { isSidebarOpen } = useSidebar();
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId;

    if (!isSidebarOpen) {
      timeoutId = setTimeout(() => {
        setShowItems(true);
      }, 300);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isSidebarOpen]);

  const handleClick = () => {
    if (isZoomApp) {
      navigate("/pro/agendas");
    }
  };

  return (
    <>
      {showItems && (
        <Fade in={!isSidebarOpen}>
          <Box
            display="flex"
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            bg="dark.500"
            borderRadius="8px"
            margin="0.75rem auto"
            width="63px"
            height="36px"
            position="sticky"
            top="0.75rem"
          >
            <SidebarToggle
              tooltipCounter={tooltipCounter}
              isFirstView={isFirstView}
              incrementTooltipCounter={incrementTooltipCounter}
            />
          </Box>
          <Box
            display="flex"
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            margin="0 auto"
            width="63px"
            height="36px"
            position="sticky"
            top="0.75rem"
          >
            <IconButton
              size="sm"
              width="auto"
              height="7"
              bg="linear-gradient(268.62deg, #8912CA -50.64%, #3E00EA 73.74%)"
              aria-label="Pro"
              icon={<Image boxSize="15px" src={isZoomApp ? proIcon : profileIcon} />}
              _hover={{
                bg: "linear-gradient(268.62deg, #8912CA -50.64%, #3E00EA 73.74%)",
              }}
              _active={{
                bg: "linear-gradient(268.62deg, #8912CA -50.64%, #3E00EA 73.74%)",
                transform: "scale(0.98)",
              }}
              onClick={handleClick}
            />
            <Box
              bg="#2A2F57"
              color="#13D1FB"
              fontSize="10"
              fontWeight="extrabold"
              padding="5px"
              borderRadius="5px"
              textAlign="center"
              width="7"
              height="7"
              verticalAlign="middle"
              lineHeight="1.75"
            >
              {participants.length}
            </Box>
          </Box>
          <Participants participants={participants} guests={guests} hostId={hostId}/>
        </Fade>
      )}
    </>
  );
};

export default SidebarClosed;
