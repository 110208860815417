import { Link as ReactLink } from "react-router-dom";
import {
  Text,
  IconButton,
  Flex,
  Badge,
  Icon,
  Circle,
  Tooltip,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import { FaEye } from "react-icons/fa";

const AgendaListItem = ({
  id,
  title,
  live,
  isPastAgenda,
  setAgendaId,
  setAgendaDeleteModalOpen,
  setAgendaDuplicateModalOpen,
}) => {
  const spanStyle = {
    position: "absolute",
    width: "75%",
    height: "100%",
    top: 0,
    bottom: 0,
    zIndex: 1,
  };

  const handleDeleteAgenda = (id) => {
    setAgendaDeleteModalOpen(true);
    setAgendaId(id);
  };

  const handleDuplicateAgenda = (id) => {
    setAgendaDuplicateModalOpen(true);
    setAgendaId(id);
  };

  return (
    <ListItem
      p="2"
      cursor="pointer"
      _hover={{
        backgroundColor: "gray.50",
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        position="relative"
      >
        <Link as={ReactLink} to={`/pro/agendas/${id}`} color="dark.400">
          <Text fontSize="12px" isTruncated={true} maxW="110px">
            {title}
          </Text>
          <span style={spanStyle} />
        </Link>
        {!isPastAgenda && live && (
          <Badge variant="active">
            <Flex alignItems="center" justifyContent="center">
              <Circle mr="1" size="1" bg="green.500" />
              Active
            </Flex>
          </Badge>
        )}
        <Flex alignItems="flex-end">
          <Tooltip hasArrow shouldWrapChildren label="Duplicate agenda">
            <IconButton
              mr="1"
              aria-label="Duplicate Agenda"
              variant="agenda"
              size="xs"
              bgColor="white"
              icon={<Icon as={FiCopy} color="gray.300" />}
              onClick={() => handleDuplicateAgenda(id)}
            />
          </Tooltip>
          {isPastAgenda ? (
            <Link as={ReactLink} to={`/pro/agendas/${id}`}>
              <IconButton
                aria-label="View Agenda"
                size="xs"
                bgColor="white"
                zIndex={3}
                icon={<Icon as={FaEye} color="gray.300" />}
                variant="agenda"
              />
            </Link>
          ) : (
            <IconButton
              aria-label="Delete Agenda"
              variant="agenda"
              size="xs"
              bgColor="white"
              icon={<Icon as={BiTrash} color="gray.300" />}
              isDisabled={live}
              onClick={() => handleDeleteAgenda(id)}
            />
          )}
        </Flex>
      </Flex>
    </ListItem>
  );
};

export default AgendaListItem;
