import { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToastNotification } from "@hooks";
import {
  useUpdateAvatarMutation,
  useUpdateColorMutation,
} from "@store/api/auth";
import { updateProfileData } from "@store/profileSlice";
import {
  Avatar,
  Tabs,
  ProfileForm,
  ProfileAvatarSettings,
  ProfileColorSettings,
} from "@components";
import {
  Flex,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  DarkMode,
} from "@chakra-ui/react";
import editProfileIcon from "@assets/svg/icon-edit.svg";

const EditProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.data);

  const { avatar, color } = profile;

  const [avatarPreview, setAvatarPreview] = useState(avatar);
  const [colorSrc, setColorSrc] = useState(color);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [settingsTabIndex, setSettingsTabIndex] = useState(0);

  const [updateAvatar, { isLoading: updatingAvatar }] =
    useUpdateAvatarMutation();
  const [updateColor, { isLoading: updatingColor }] = useUpdateColorMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showErrorToast, showSuccessToast } = useToastNotification();

  const profileTabs = [{ id: 0, label: "Profile", content: <ProfileForm /> }];

  const settingsTab = [
    {
      id: 0,
      label: "Avatar",
      content: (
        <ProfileAvatarSettings
          color={color}
          avatarPreview={avatarPreview}
          setAvatarPreview={setAvatarPreview}
          setIsSaveDisabled={setIsSaveDisabled}
        />
      ),
    },
    {
      id: 1,
      label: "Color",
      content: (
        <ProfileColorSettings
          colorSrc={colorSrc}
          setColorSrc={setColorSrc}
          setIsSaveDisabled={setIsSaveDisabled}
        />
      ),
    },
  ];

  const handleUpdateAvatar = useCallback(async () => {
    try {
      const updatedAvatar = avatarPreview === null ? "" : avatarPreview;
      const result = await updateAvatar({ avatar: updatedAvatar }).unwrap();
      dispatch(updateProfileData(result.user));
      showSuccessToast("Updated avatar successfully");
    } catch (error) {
      showErrorToast("Error updating avatar, please try again.");
    } finally {
      setIsSaveDisabled(true);
    }
  }, [
    avatarPreview,
    updateAvatar,
    dispatch,
    updateProfileData,
    showErrorToast,
    showSuccessToast,
  ]);

  const handleUpdateColor = useCallback(async () => {
    try {
      const result = await updateColor({ color: colorSrc }).unwrap();
      setColorSrc(result.user.color);
      dispatch(updateProfileData(result.user));
      showSuccessToast("Updated profile color successfully");
    } catch (error) {
      showErrorToast("Error updating profile color, please try again.");
    } finally {
      setIsSaveDisabled(true);
    }
  }, [
    colorSrc,
    updateColor,
    setColorSrc,
    dispatch,
    updateProfileData,
    showErrorToast,
    showSuccessToast,
  ]);

  const handleSave = useCallback(
    () => (settingsTabIndex === 0 ? handleUpdateAvatar() : handleUpdateColor()),
    [settingsTabIndex, handleUpdateAvatar, handleUpdateColor]
  );

  const handleCloseModal = () => {
    onClose();
    setAvatarPreview(avatar);
  };

  return (
    <>
      <Flex w="full" align="center" justify="space-between">
        <Avatar
          color={profile.color}
          avatar={profile.avatar}
          size="xl"
          rounded={true}
          edit={true}
          onEdit={onOpen}
        />
        <DarkMode>
          <Button
            variant="secondary"
            rightIcon={<Image boxSize="15px" src={editProfileIcon} />}
            onClick={onOpen}
          >
            Edit Avatar
          </Button>
        </DarkMode>
      </Flex>
      <Tabs tabs={profileTabs} variant="profile" />
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xs">
        <ModalOverlay />
        <ModalContent bg="dark.500" borderRadius="16px">
          <ModalCloseButton bg="dark.700" color="gray.400" size="sm" />
          <ModalBody>
            <Tabs
              index={settingsTabIndex}
              tabs={settingsTab}
              onChange={(index) => setSettingsTabIndex(index)}
              variant="profile"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              isFullWidth
              isDisabled={isSaveDisabled}
              onClick={handleSave}
              isLoading={updatingAvatar || updatingColor}
              loadingText="Saving..."
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditProfile;
