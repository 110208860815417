import { useState, useEffect, useCallback, useRef } from "react";
import { Text } from "@chakra-ui/react";
import dayjs from "dayjs";

const CountUpTimer = ({ startTime, startTimer, stopTimer }) => {
  const timerInterval = useRef();
  const [duration, setDuration] = useState(0);
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.ceil((duration % 3600) % 60);

  const displayTime = (time) => (time < 10 ? `0${time}` : time);

  const endTimer = useCallback(() => {
    if (timerInterval.current && stopTimer) {
      clearInterval(timerInterval.current);
      timerInterval.current = null;
    }
  }, [stopTimer]);

  const getTimerDuration = useCallback(() => {
    const now = dayjs();
    const counterStartTime = dayjs(startTime);
    const timeDifference = now.diff(counterStartTime, "second");
    setDuration(() => parseInt(timeDifference, 10));
  }, [startTime]);

  useEffect(() => {
    if (!timerInterval.current && startTimer) {
      timerInterval.current = setInterval(getTimerDuration, 1000);
    }

    return () => {
      endTimer();
    };
  }, [startTime, startTimer, endTimer, getTimerDuration]);

  useEffect(() => {
    endTimer();
  }, [stopTimer, endTimer]);

  return (
    <Text fontSize="x-small" color="dark.400">
      {displayTime(hours)}:{displayTime(minutes)}:{displayTime(seconds)}
    </Text>
  );
};

export default CountUpTimer;
