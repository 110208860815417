import {
  participantJoinedMeeting,
  participantLeftMeeting,
  meeetingEnded,
  sendPoints,
  recieveAnalytics,
  receiveEPulse,
  disconnectSocket,
} from "@socket";
import {
  updateParticipantJoinedMeeting,
  updateParticipantLeft,
  clearMeetingState,
  updateEPulseData,
} from "@store/meetingSlice";
import { clearProfileState } from "@store/profileSlice";
import { clearAllNotifications } from "@store/notificationsSlice";
import { updatePoints, updateEScore } from "@store/engagementSlice";
import { stopCurrentAgenda } from "@store/agendasSlice";
import { socketInitiated, saveUserMeetingInfo } from "@store/customActions";
import { isZoomApp, saveMeetingInfo, clearMeetingInfo } from "@utils/helpers";

const meetingSocketMiddlewares = (api) => (next) => (action) => {
  if (action.type === socketInitiated.type) {
    participantJoinedMeeting.subscribe((data) => {
      api.dispatch(updateParticipantJoinedMeeting(data.meetingObject));
    });
  
    participantLeftMeeting.subscribe((data) => {
      api.dispatch(
        updateParticipantLeft({
          profileId: action.payload.profileId,
          meetingObject: data.meetingObject,
        })
      );
    });
  
    meeetingEnded.subscribe((data) => {
      api.dispatch(stopCurrentAgenda());
      api.dispatch(clearAllNotifications());
      if (!isZoomApp) {
        api.dispatch(clearProfileState());
        api.dispatch(clearMeetingState());
        clearMeetingInfo();
      }
      disconnectSocket();
    });

    recieveAnalytics.subscribe((data) => {
      api.dispatch(updateEScore({ eScore: data.engagementPercentage }));
    });

    sendPoints.subscribe((data) => {
      const { totalPoints } = data;
      api.dispatch(updatePoints({ points: totalPoints }));
    });
  }

  if (
    action.type === socketInitiated.type &&
    action.payload.profileId === action.payload.meetingHostId
  ) {
    receiveEPulse.subscribe((data) => {
      api.dispatch(updateEPulseData(data));
    });
  }

  if (action.type === saveUserMeetingInfo.type) {
    saveMeetingInfo(action.payload);
  }

  return next(action);
};

export default meetingSocketMiddlewares;
