import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const meetingApi = createApi({
  reducerPath: "meetingApi",
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        const parsedToken = JSON.parse(token);
        headers.set("authorization", `Bearer ${parsedToken.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      checkExistingMeeting: builder.mutation({
        query: (body) => ({
          url: "/meeting/participant/rejoin",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
      joinMeeting: builder.mutation({
        query: (body) => ({
          url: "/meeting/participant/join",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
      guestJoinMeeting: builder.mutation({
        query: (body) => ({
          url: "/meeting/participant/guest/join",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
    };
  },
});

export const {
  useCheckExistingMeetingMutation,
  useJoinMeetingMutation,
  useGuestJoinMeetingMutation,
} = meetingApi;
