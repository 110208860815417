import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: [],
  chat: {},
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotificationToChatBox: (state, action) => {
      const { notification } = action.payload;
      state.chat = notification;
    },
    addNotificationToSidebar: (state, action) => {
      const { notification } = action.payload;
      state.sidebar.unshift(notification);
    },
    addAllNotificationsToSidebar: (state, action) => {
      const { notifications } = action.payload;
      state.sidebar = notifications;
    },
    removeNotificationFromChatBox: (state) => {
      state.chat = initialState.chat;
    },
    removeNotificationFromSidebar: (state, action) => {
      const { notificationId } = action.payload;
      const filteredNotifications = state.sidebar.filter(
        (notification) => notification._id !== notificationId
      );

      state.sidebar = filteredNotifications;
    },
    clearAllNotifications: () => initialState,
  },
});

const { reducer, actions } = notificationsSlice;

export const {
  addNotificationToSidebar,
  addNotificationToChatBox,
  removeNotificationFromChatBox,
  addAllNotificationsToSidebar,
  removeNotificationFromSidebar,
  clearAllNotifications,
} = actions;

export default reducer;
