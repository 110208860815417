import useAuth from "@hooks/useAuth";
import { Navigate, useLocation } from "react-router-dom";
import { isZoomApp } from "@utils/helpers";

const RequireAuth = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const currentPath = location.pathname;
  const path = isZoomApp ? '/login' : (location?.state?.from ? -1 : '/');
  return isAuthenticated ? children : <Navigate to={path} replace={true} state={{ from: currentPath }} />;
};

export default RequireAuth;
