import { chakra, Image } from "@chakra-ui/react";
import { CountUpTimer } from "@components";
import stopwatchIcon from "@assets/svg/icon-epulse-timer.svg";

const EPulseTimer = ({ agenda }) => {
  return (
    <>
      <chakra.span>
        <Image src={stopwatchIcon} boxSize="12px" mr="1" />
      </chakra.span>
      <CountUpTimer
        startTimer={agenda.live}
        stopTimer={!agenda.live}
        startTime={agenda.startTime}
      />
    </>
  );
};

export default EPulseTimer;
