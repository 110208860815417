import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMeeting } from "@hooks";
import { LICENSE_TYPES } from "@constants";
import { Box, Flex, Button, useDisclosure } from "@chakra-ui/react";
import {
  ProUsersCount,
  ProWelcomeCard,
  AgendaContainer,
  ProShareMeetingLink,
  ProStartTrialModal,
} from "@components";
import { BiChevronLeft } from "react-icons/bi";

const ProView = () => {
  const navigate = useNavigate();
  const {
    meetingParticipants,
    meetingObj,
    isMeetingActive,
    isHostOrCoHost,
    isHost,
  } = useMeeting();
  const profile = useSelector((state) => state.profile.data);
  const { licenseType } = profile;
  const {
    isOpen: isModalOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (licenseType === LICENSE_TYPES.Basic) {
      onOpenModal();
    }
  }, [licenseType, onOpenModal]);

  return (
    <>
      <ProStartTrialModal isOpen={isModalOpen} onClose={onCloseModal} />
      <Box margin="0.75rem" overflow="hidden" height="calc(100vh - 140px)">
        <Flex justifyContent="space-between">
          <Button
            leftIcon={<BiChevronLeft />}
            size="sm"
            variant="secondary"
            onClick={handleClick}
          >
            Back
          </Button>
          <ProUsersCount count={meetingParticipants.length} mr="2" />
        </Flex>
        <ProWelcomeCard />
        {isHostOrCoHost(profile?._id) &&
          isMeetingActive &&
          meetingObj?.meetingInstances?.length && (
            <ProShareMeetingLink
              profile={profile}
              meetingInstance={meetingObj?.meetingInstances}
              role={isHost(profile?._id) ? "Host" : "Co-host"}
            />
          )}
        <AgendaContainer />
      </Box>
    </>
  );
};

export default ProView;
