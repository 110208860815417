import { Fragment } from "react";
import { AgendaListItem } from "@components";
import { Skeleton, Stack, List, Divider, useToken } from "@chakra-ui/react";

const AgendaList = ({
  agendas,
  emptyStateComponent,
  isPastAgenda,
  loadingAgendas,
  setAgendaId,
  setAgendaDeleteModalOpen,
  setAgendaDuplicateModalOpen,
}) => {
  const [gray100, gray300] = useToken("colors", ["gray.100", "gray.300"]);

  if (loadingAgendas) {
    const ITEMS = [1, 2, 3];

    return (
      <Stack my="1">
        {ITEMS.map((s) => (
          <Skeleton key={s} height="40px" />
        ))}
      </Stack>
    );
  }

  return agendas?.length > 0 ? (
    <List
      id="host-onboarding-step-1"
      overflowY="auto"
      minHeight="calc(100vh - 920px)"
      maxHeight="calc(100vh - 485px)"
      pr="5px"
      my="1"
      sx={{
        scrollbarColor: `${gray300} ${gray100}`,
        scrollbarWidth: "thin",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "gray.100",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "gray.300",
          borderRadius: "5px",
        },
      }}
    >
      {agendas.map((agenda, index) => {
        const { _id, title, live } = agenda;

        return (
          <Fragment key={_id}>
            <AgendaListItem
              id={_id}
              title={title}
              live={live}
              isPastAgenda={isPastAgenda}
              setAgendaId={setAgendaId}
              setAgendaDeleteModalOpen={setAgendaDeleteModalOpen}
              setAgendaDuplicateModalOpen={setAgendaDuplicateModalOpen}
            />
            {index !== agendas.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </List>
  ) : (
    emptyStateComponent
  );
};

export default AgendaList;
