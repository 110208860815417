import { useRef } from "react";
import { useSelector } from "react-redux";
import { ResponsiveLine } from "@nivo/line";
import { Box, Text, useToken } from "@chakra-ui/react";
import dayjs from "dayjs";

const EPulseChart = ({ agenda }) => {
  const ref = useRef(null);
  const [success, high, medium, low, textColor] = useToken("colors", [
    "success",
    "green.300",
    "red.300",
    "yellow.300",
    "gray.400",
  ]);

  const Epulse = useSelector((state) => state.meeting.ePulse);
  const defaultEpulse = { data: [] };
  const ePulseData = agenda.live ? Epulse : defaultEpulse;

  const commonProperties = {
    margin: { top: 10, right: 10, bottom: 10, left: 35 },
    animate: true,
    enableSlices: "x",
  };

  const EPulseTick = (tick) => {
    const tickLabels = {
      50: { label: "High", color: high },
      0: { label: "Med", color: medium },
      "-50": { label: "Low", color: low },
    };

    const label = tickLabels[tick.value]?.label;
    const color = tickLabels[tick.value]?.color;

    if (!label) return null;

    return (
      <g transform={`translate(${tick.x - 5},${tick.y})`}>
        <text
          dominantBaseline="middle"
          textAnchor="end"
          transform="translate(-10,0)"
          style={{
            fontSize: "8px",
            fill: textColor,
          }}
        >
          {label}
        </text>
        <path
          d="M2.61905 0L0 3H5L2.61905 0Z"
          fill={color}
          transform="translate(-7, -2)"
        />
      </g>
    );
  };

  return (
    <Box ref={ref} position="relative" zIndex={0} height="150px">
      <ResponsiveLine
        {...commonProperties}
        data={[
          {
            id: "points",
            data: ePulseData.data.map((item) => ({
              x: dayjs(item.time).toDate(),
              y: item.pulse,
            })),
          },
        ]}
        curve="monotoneX"
        enablePoints={false}
        colors={success}
        yScale={{
          type: "linear",
          stacked: false,
          min: -50,
          max: 50,
        }}
        enableGridX={false}
        isInteractive={false}
        axisBottom={null}
        animate={false}
        pointLabelYOffset={0}
        motionStiffness={120}
        motionDamping={50}
        enableArea={false}
        axisLeft={{
          renderTick: EPulseTick,
        }}
      />
    </Box>
  );
};

export default EPulseChart;
