import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Card, CardFooter, Duration, AlertDialog } from "@components";
import {
  Heading,
  Flex,
  IconButton,
  Text,
  Image,
  Tooltip,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { ActivityResultAccordion } from "@components/Activity";
import {
  activityIcons,
  activityTitles,
  activityTypes,
} from "@constants/activities";
import { updateSelectedActivity } from "@store/activitySlice";
import { useGoLiveMutation, useStopLiveMutation } from "@store/api/activities";
import { useToastNotification } from "@hooks";
import settingsIcon from "@assets/settings-icon.svg";
import { getLiveButtonProps } from "@components/Activity/helpers";

const SingleActivityCard = ({
  activity,
  agenda,
  changeViewData,
  setActivityType,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isOpen: isAccordionOpen, onToggle: toggleAccordion } =
    useDisclosure();
  const [goLive, { isLoading: isGoingLive }] = useGoLiveMutation();
  const [stopLive, { isLoading: isStoppingLive }] = useStopLiveMutation();

  const dispatch = useDispatch();
  const countDownRef = useRef(null);
  const { showSuccessToast, showErrorToast } = useToastNotification();

  const liveActivity = useSelector((state) => state.activity.liveActivity);
  const hasLiveActivity = useMemo(
    () => !!Object.keys(liveActivity).length,
    [liveActivity]
  );
  const liveTools = useSelector((state) => state.activity.liveTools);
  const hasLiveTools = useMemo(
    () => !!Object.keys(liveTools).length,
    [liveTools]
  );
  const { views, setView } = changeViewData;
  const activityTitle = activityTitles[activity.type];
  const isActivityEditable =
    activity.endTime || activity.live || agenda.endTime;

  const renderCountdown = activity.startTime ? (
    <Countdown
      date={dayjs(activity.startTime || Date.now())
        .add(activity.settings.duration, "minute")
        .toDate()}
      renderer={Duration}
      autoStart={false}
      ref={countDownRef}
    />
  ) : (
    <Duration minutes={activity.settings.duration} seconds={0} />
  );

  const handleOpenAccordion = () => {
    if (isAccordionOpen) return;
    return toggleAccordion();
  };

  const startActivity = useCallback(async () => {
    try {
      await goLive(activity._id).unwrap();
      handleOpenAccordion();
      showSuccessToast(`${activityTitle} is now running`);
    } catch (error) {
      showErrorToast(error.data?.message || `Error starting ${activityTitle}`);
    }
  }, [activityTitle, activity._id, goLive, showSuccessToast, showErrorToast]);

  const stopActivity = useCallback(async () => {
    try {
      await stopLive(activity._id).unwrap();
      setIsDialogOpen(false);
      showSuccessToast(`${activityTitle} has ended`);
    } catch (error) {
      showErrorToast(error.data?.message || `Error ending ${activityTitle}`);
    }
  }, [activityTitle, activity._id, stopLive, showSuccessToast, showErrorToast]);

  const getIconButtonProps = useCallback(
    (activity) =>
      getLiveButtonProps(
        activity,
        agenda.live,
        activity.type === activityTypes.send ? hasLiveTools : hasLiveActivity,
        startActivity,
        () => setIsDialogOpen(true),
        isGoingLive
      ),
    [
      agenda.live,
      startActivity,
      hasLiveActivity,
      hasLiveTools,
      setIsDialogOpen,
      isGoingLive,
    ]
  );

  useEffect(() => {
    if (activity.live && countDownRef.current) {
      countDownRef.current.getApi().start();
    }
  }, [countDownRef, activity.live]);

  return (
    <Card
      key={activity._id}
      p={2}
      borderRadius="10px 10px 0 0"
      footer={
        <CardFooter py={2} px={3} mb={2}>
          <ActivityResultAccordion
            type={activity.type}
            response={activity.response}
            options={activity.options}
            title={activity.title}
            settings={activity.settings}
            numberOfParticipants={activity?.numberOfParticipants || 0}
            toggleAccordion={toggleAccordion}
            isAccordionOpen={isAccordionOpen}
          />
        </CardFooter>
      }
    >
      <AlertDialog
        title={`End ${activityTitle}`}
        headerIcon={activityIcons[activity.type]}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        primaryButton={
          <Button
            size="sm"
            colorScheme="red"
            onClick={stopActivity}
            ml={3}
            disabled={isStoppingLive}
          >
            End
          </Button>
        }
        secondaryButton={
          <Button
            size="sm"
            onClick={() => setIsDialogOpen(false)}
            bg="white"
            disabled={isStoppingLive}
          >
            Cancel
          </Button>
        }
      >
        {`Are you sure you want to end ${activityTitle}?`}
      </AlertDialog>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" grow={0} maxWidth="calc(100% - 100px)">
          <Image h={6} w={6} src={activityIcons[activity.type]} mr={1} />
          <Heading
            as="h4"
            fontWeight="600"
            fontSize="12px"
            color="dark.400"
            isTruncated
          >
            {activity.displayName}
          </Heading>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          w="100px"
          shrink={0}
        >
          {activity.endTime ? (
            <Text fontWeight="600" fontSize="12px" color="gray.300">
              Done
            </Text>
          ) : activity?.settings?.duration ? (
            renderCountdown
          ) : null}
          <Tooltip
            hasArrow
            shouldWrapChildren
            label={getIconButtonProps(activity)["aria-label"]}
          >
            <IconButton
              {...getIconButtonProps(activity)}
              ml={2.5}
              variant="ghost"
              size="xs"
              bg="transparent"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _disabled={{ bg: "transparent" }}
            />
          </Tooltip>
          <Flex>
            <IconButton
              aria-label="Edit Activity"
              icon={<Image src={settingsIcon} />}
              variant="ghost"
              disabled={isActivityEditable}
              ml={2}
              size="xs"
              bg="transparent"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              onClick={() => {
                setView(views.add);
                setActivityType(activity.type);
                dispatch(updateSelectedActivity({ activity }));
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default SingleActivityCard;
