import { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import "focus-visible/dist/focus-visible";
import { Layout, FullPageLoader, ZoomAppAuthState, WebAppAuthState } from "@components";
import { AppRoutes } from "@routes";
import {
  useHandleIconClick,
  useSocketConnections,
  useNotifications,
  useMeeting,
  useSdk,
} from "@hooks";
import {
  initiateSocket,
  iconChat,
  notification,
  disconnectSocket,
} from "@socket";
import { socketInitiated } from "@store/customActions";
import { updateCoins } from "@store/engagementSlice";

import { useGetAllIconsQuery } from "@store/api/icon";
import { LoadGoogleAnalytics } from "@utils/helpers";
import { ActivityParticipantModal } from "@components/Activity";
import { APP_TYPE } from "@constants";

function App() {
  const dispatch = useDispatch();
  const { handleAddNotification } = useNotifications();
  const {
    userContextStatus,
    isAuthorized,
    fetchingProfile,
    fetchProfileError,
    profileId,
    isAuthenticated,
    hasInValidMeetingInfo,
    appType
  } = useSdk();
  const analytics = useSelector((state) => state.meeting.analytics);
  const { setTriggerLiveAnaltyics } = useSocketConnections();
  const { broadcastIconClick } = useHandleIconClick();
  useGetAllIconsQuery(null, {
    skip: !profileId,
  });
  const { isMeetingActive } = useMeeting();
  const liveActivity = useSelector((state) => state.activity.liveActivity);
  const showResult = useSelector((state) => state.activity.showResult);
  // TODO: move to useMeeting hook
  const meetingHostId = useSelector((state) => state.meeting.data.meetingObject?.hostId);
  const meetingUuid = useSelector((state) => state.meeting.data.meetingObject?._id);
  const meetingInstanceId = useSelector((state) => state.meeting.data.meetingObject?.meetingInstances?.[0]._id);

  // TODO: clean up and remove useSocketConnection hook
  useEffect(() => {
    if (analytics.live) {
      setTriggerLiveAnaltyics(true);
    } else {
      setTriggerLiveAnaltyics(false);
    }
  }, [analytics.live, setTriggerLiveAnaltyics]);

  useEffect(() => {
    if (!isAuthenticated || !meetingInstanceId) {
      console.info("No meetingInstanceId or user is not authenticated");
      return;
    };

    initiateSocket(profileId, meetingInstanceId);
    dispatch(socketInitiated({
      meetingUuid,
      meetingHostId,
      profileId,
    }));

    // TODO: clean up iconChat and notification subscription
    iconChat.subscribe((data) => {
      broadcastIconClick({ iconId: data.iconId, color: data.color });
    });

    notification.subscribe((data) => {
      const { type, meta } = data;

      if (type === "coins") {
        dispatch(updateCoins({ coins: meta?.value }));
      }

      handleAddNotification(data);
    });

    return () => {
      iconChat.unsubscribe();
      notification.unsubscribe();
      disconnectSocket();
    }
  }, [
    profileId,
    meetingHostId,
    meetingUuid,
    meetingInstanceId, 
    isAuthenticated,
    broadcastIconClick,
    dispatch,
    handleAddNotification
  ])

  if (fetchingProfile) {
    return (
      <Layout>
        <FullPageLoader/>
      </Layout>
    )
  }

  if (appType === APP_TYPE.ZoomApp && (!isAuthorized || fetchProfileError)) {
    return (
      <Layout>
        <ZoomAppAuthState userContextStatus={userContextStatus} />
      </Layout>
    )
  }

  if (appType === APP_TYPE.WebApp && hasInValidMeetingInfo) {
    return (
      <Layout>
        <WebAppAuthState />
      </Layout>
    );
  }

  return (
    <HelmetProvider>
      <LoadGoogleAnalytics />
      <Layout>
        {isMeetingActive && (liveActivity.live || showResult) && (
          <ActivityParticipantModal />
        )}
        <Suspense fallback={<FullPageLoader />}>
          <AppRoutes />
        </Suspense>
      </Layout>
    </HelmetProvider>
  );
}

export default App;
