import { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGuestJoinMeetingMutation } from "@store/api/meeting";
import { saveUserMeetingInfo } from "@store/customActions";
import { useToastNotification } from "@hooks";
import { CONTEXT_STATUS, APP_TYPE } from "@constants";
import { encodeObject, getUserMeetingInfo, clearMeetingInfo } from "@utils/helpers";

const useWebView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [guestJoinMeeting] = useGuestJoinMeetingMutation();
  const { showErrorToast } = useToastNotification();

  const [userContextStatus, setUserContextStatus] = useState(CONTEXT_STATUS.UNAUTHENTICATED);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasInValidMeetingInfo, setHasInValidMeetingInfo] = useState(false);
  const location = useLocation();
  const profileId = useSelector((state) => state.profile.data?._id);
  const isAuthenticated = useSelector((state) => state.profile.isAuthenticated);

  const queryParams = new URLSearchParams(location.search);
  const platformMeetingId = queryParams.get("platformMeetingId");
  const platformMeetingInstanceId = decodeURIComponent(queryParams.get("platformMeetingInstanceId"));

  const hashAndSaveMeetingInfo = useCallback((userMeetingInfo) => {
    const hashedUserMeetingInfo = encodeObject(userMeetingInfo);
    dispatch(saveUserMeetingInfo(hashedUserMeetingInfo));
  }, [dispatch]);

  const joinMeeting = useCallback(async (userMeetingInfo) => {
    setIsLoading(true);
    const meeting = await guestJoinMeeting({ ...userMeetingInfo, appType: APP_TYPE.WebApp });
    setIsLoading(false);

    if (meeting.error) {
      showErrorToast(meeting.error?.data?.message ?? "We cannot connect you to this meeting at this time");
      clearMeetingInfo();
      return;
    }
  
    hashAndSaveMeetingInfo(userMeetingInfo);
    navigate(`/?platformMeetingId=${platformMeetingId}&platformMeetingInstanceId=${platformMeetingInstanceId}`);
  }, [guestJoinMeeting, hashAndSaveMeetingInfo, showErrorToast, navigate, platformMeetingId, platformMeetingInstanceId]);

  const joinMeetingAsGuest = useCallback(async (name, email) => {
    const userMeetingInfo = {
      name,
      email,
      platformMeetingId,
      platformMeetingInstanceId,
    };
    await joinMeeting(userMeetingInfo);
  }, [joinMeeting, platformMeetingId, platformMeetingInstanceId]);

  useEffect(() => {
    const userMeetingInfo = getUserMeetingInfo();
    if (userMeetingInfo?.platformMeetingInstanceId === platformMeetingInstanceId) {
      joinMeeting(userMeetingInfo)
    }
  }, []);

  useEffect(() => {
    if (profileId) {
      setIsAuthorized(true);
      setUserContextStatus(CONTEXT_STATUS.AUTHORIZED);
      setHasInValidMeetingInfo(false);
    }
  }, [profileId]);

  useEffect(() => {
    if (!(platformMeetingId && platformMeetingInstanceId)) {
      setIsAuthorized(false);
      setUserContextStatus(CONTEXT_STATUS.UNAUTHENTICATED);
      setHasInValidMeetingInfo(true);
      return;
    }

    setHasInValidMeetingInfo(false);
  }, [platformMeetingId, platformMeetingInstanceId]);

  return {
    profileId,
    isLoading,
    hasInValidMeetingInfo,
    platformMeetingId,
    platformMeetingInstanceId,
    isAuthenticated,
    userContextStatus,
    isAuthorized,
    joinMeetingAsGuest,
    appType: APP_TYPE.WebApp,
  };
};

export default useWebView;
