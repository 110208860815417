import { lazy } from "react";

export const Login = lazy(() => import("src/pages/Login"));
export const ZoomInstallation = lazy(() => "src/pages/auth/ZoomInstallation");
export const Register = lazy(() => import("src/pages/Register"));
export const ConfirmEmail = lazy(() => import("src/pages/ConfirmEmail"));
export const ResetPassword = lazy(() => import("src/pages/ResetPassword"));
export const NewPassword = lazy(() => import("src/pages/NewPassword"));
export const CheckEmail = lazy(() => import("src/pages/CheckEmail"));
export const VerifyEmail = lazy(() => import("src/pages/auth/VerifyEmail"));
export const Onboarding = lazy(() => import("src/pages/auth/Onboarding"));
export const Setup = lazy(() => import("src/pages/auth/Setup"));
export const AccountView = lazy(() =>
  import("src/pages/auth/Account/AccountView")
);
export const Home = lazy(() => import("src/pages/auth/Home"));
export const MeetingView = lazy(() => import("src/pages/auth/MeetingView"));
export const AgendaView = lazy(() =>
  import("src/components/Agenda/AgendaView")
);
export const JoinMeeting = lazy(() => import("@pages/JoinMeeting/JoinMeeting"));
export const SelectUserType = lazy(() => import("@pages/auth/SelectUserType"));
