import React, { useEffect } from "react";
import { ActivityEmptyState } from "@components";
import  { useSelector, useDispatch } from "react-redux";
import { ActivityCard } from "@components/Activity";
import { checkForLiveActivity } from "@store/activitySlice";

const ActivityList = ({ agenda, changeViewData, setActivityType }) => {
  const dispatch = useDispatch();
  const activities = useSelector((state) => state.activity.activities);

  useEffect(() => {
    dispatch(checkForLiveActivity());
  }, [dispatch]);

  return (
    <>
      { activities?.length ? (
        activities.map((activity) => (
          <ActivityCard
            key={activity._id}
            activity={activity}
            agenda={agenda}
            changeViewData={changeViewData}
            setActivityType={setActivityType}
          />
        ))
      ) : (
        <ActivityEmptyState changeViewData={changeViewData} />
      )}
    </>
  )
}

export default ActivityList;
