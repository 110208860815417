import React from "react";
import { CardFooter, AlertDialog } from "@components";
import { Flex, Button, HStack } from "@chakra-ui/react";

const SingleActivityFooter = ({
  isLoading,
  isSavingActivity,
  isDeletingActivity,
  handleDelete,
  handleSave,
  activity = {},
  isDisabled,
  handleModalOpen,
  handleModalClose,
  isModalOpen,
  activityTitle,
}) => {
  const hasActivity = Object.keys(activity).length;

  return (
    <>
      <AlertDialog
        title={`Delete ${activityTitle}`}
        variant="delete"
        isOpen={isModalOpen}
        onClose={handleModalClose}
        primaryButton={
          <Button
            size="sm"
            colorScheme="red"
            onClick={() => handleDelete(activity._id)}
            isLoading={isDeletingActivity}
            isDisabled={isDisabled || isLoading}
            ml={3}
          >
            Delete
          </Button>
        }
        secondaryButton={
          <Button
            size="sm"
            onClick={handleModalClose}
            border="1px solid"
            borderColor="gray.300"
            bg="white"
            isDisabled={isLoading}
          >
            Cancel
          </Button>
        }
      >
        {`Are you sure you want to delete this ${activityTitle}?`}
      </AlertDialog>
      <CardFooter p={3}>
        {hasActivity ? (
          <HStack>
            <Button
              w="full"
              size="sm"
              colorScheme={"red"}
              fontSize="12px"
              fontWeight="600"
              onClick={handleModalOpen}
              isDisabled={isLoading}
            >
              Delete
            </Button>
            <Button
              size="sm"
              isFullWidth
              variant="primary"
              isDisabled={isDisabled || isLoading}
              onClick={handleSave}
              isLoading={isSavingActivity}
            >
              Save
            </Button>
          </HStack>
        ) : (
          <Button
            size="sm"
            isFullWidth
            variant="primary"
            isLoading={isSavingActivity}
            isDisabled={isDisabled || isLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        )}
      </CardFooter>
    </>
  );
};

export default SingleActivityFooter;
