import { useCallback } from "react";
import { Text, VStack } from "@chakra-ui/react";
import { ProSelect, SelectOption } from "@components";
import {
  USE_CASE_OPTIONS,
  MEETING_FREQUENCY_OPTIONS,
  COMPANY_SIZE_OPTIONS,
} from "@constants";

const ProStartTrialStepTwo = ({ formFields, setFormFields }) => {
  const handleOnChange = useCallback(
    (selected, option) => {
      setFormFields({ ...formFields, [option]: selected });
    },
    [formFields]
  );

  return (
    <>
      <Text color="dark.300" fontSize="12px">
        How do you plan to Host with Townhall Pro?
      </Text>
      <VStack>
        {/* This component uses chakra-react-select <Select> component under the hood,
        so we should create a universal one using this at some point and update where
        the regular Chakra <Select> is being used (e.g. Profile) */}
        {/* Comment out the Use Case Multi Component until we figure out the issue
        with not being able to select this field on Windows */}
        {/* <ProSelect
          name="case"
          label="Use Case"
          isMulti
          hasStickyGroupHeaders
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          options={USE_CASE_OPTIONS}
          components={{ Option: SelectOption }}
          placeholder="Select multiple..."
          value={formFields.caseOptionSelected}
          onChange={(selected) => {
            const selectedValues = selected.map(({ value }) => value);
            handleOnChange(selectedValues, "useCase");
          }}
        /> */}
        <ProSelect
          name="frequency"
          label="Meeting Frequency"
          options={MEETING_FREQUENCY_OPTIONS}
          placeholder="Select one..."
          value={formFields.meetingOptionSelected}
          onChange={(selected) =>
            handleOnChange(selected.value, "meetingFrequency")
          }
        />
        <ProSelect
          name="size"
          label="Company Size"
          options={COMPANY_SIZE_OPTIONS}
          placeholder="Select one..."
          value={formFields.companyOptionSelected}
          onChange={(selected) => handleOnChange(selected.value, "companySize")}
        />
      </VStack>
    </>
  );
};

export default ProStartTrialStepTwo;
