import { createSlice } from "@reduxjs/toolkit";
import { meetingApi } from "@store/api/meeting";
import { agendaApi } from "@store/api/agenda";
import { getUserIdFromMeetingInstanceId } from "@utils/helpers";

const initialState = {
  data: {
    inAMeeting: false,
    meetingObject: null,
    meetingActivities: null,
    meetingHost: null,
  },
  ePulse: {
    data: [],
  },
  analytics: {
    live: false,
    data: [],
  },
};

const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    updateParticipantJoinedMeeting: (state, { payload }) => {
      const { liveActivity = {} } = payload.meetingInstances[0]?.agenda || {};
      state.data.inAMeeting = true;
      state.data.meetingObject = payload;
      state.data.meetingActivities = {
        [liveActivity?.type]: liveActivity?.data,
      };
      state.data.meetingHost =
        payload.meetingInstances[0].participantsInMeetingOnTH.find(
          (participant) => participant._id === payload.hostId
        );
    },
    updateParticipantLeft: (state, action) => {
      const { meetingObject, profileId } = action.payload;
      if (meetingObject?._id === state.data.meetingObject?._id) {
        const foundMe = meetingObject.meetingInstances[0].participantsInMeetingOnTH.find(
          (item) => {
            return item._id === profileId;
          }
        );

        if (foundMe) {
          state.data.meetingObject = meetingObject;
        } else {
          return initialState;
        }
      }
    },
    updateLiveMeetingStatus: (state, action) => {
      state.analytics.live = action.payload;
    },
    updateAnalyticsData: (state, action) => {
      state.analytics.data.push({ uv: action.payload });
      if (state.analytics.data.length > 6) {
        state.analytics.data.shift();
      }
    },
    updateEPulseData: (state, action) => {
      state.ePulse.data = action.payload;
    },
    updateMeetingActivityStarted: (state, action) => {
      const { type, activity } = action.payload;
      const meetingActivities = state.data.meetingActivities || {};
      const updatedMeetingActivity = { ...meetingActivities, [type]: activity };
      state.data.meetingActivities = updatedMeetingActivity;
    },
    updateMeetingActivityEnded: (state, action) => {
      const { type, activityId } = action.payload;
      const activity = state.data.meetingActivities?.[type];
      if (activity && activity._id === activityId) {
        state.data.meetingActivities[type].live = false;
      }
    },
    updateMeetingObjectAgenda: (state, action) => {
      state.data.meetingObject.meetingInstances[0].agenda = action.payload;
    },
    clearMeetingState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      agendaApi.endpoints.stopLiveAgenda.matchFulfilled,
      (state) => {
        state.ePulse.data = [];
      }
    );
    builder.addMatcher(
      meetingApi.endpoints.checkExistingMeeting.matchFulfilled,
      (state, { payload }) => {
        const { liveActivity = {} } = payload.meetingInstances[0]?.agenda || {};
        state.data.inAMeeting = true;
        state.data.meetingObject = payload;
        state.data.meetingActivities = {
          [liveActivity?.type]: liveActivity?.data,
        };
        state.data.meetingHost =
          payload.meetingInstances[0].participantsInMeetingOnTH.find(
            (participant) => participant._id === getUserIdFromMeetingInstanceId(payload.meetingInstances[0]._id)
          );
      }
    );
    builder.addMatcher(
      meetingApi.endpoints.guestJoinMeeting.matchFulfilled,
      (state, { payload }) => {
        const { liveActivity = {} } = payload.meetingInstances[0]?.agenda || {};
        state.data.inAMeeting = true;
        state.data.meetingObject = payload;
        state.data.meetingActivities = {
          [liveActivity?.type]: liveActivity?.data,
        };
        state.data.meetingHost =
          payload.meetingInstances[0].participantsInMeetingOnTH.find(
            (participant) => participant._id === getUserIdFromMeetingInstanceId(payload.meetingInstances[0]._id)
          );
      }
    );
  },
});

const { reducer, actions } = meetingSlice;

export const {
  clearMeetingState,
  updateParticipantJoinedMeeting,
  updateParticipantLeft,
  updateLiveMeetingStatus,
  updateAnalyticsData,
  updateEPulseData,
  updateMeetingActivityStarted,
  updatePollAnswer,
  updateMeetingActivityEnded,
  updateMeetingObjectAgenda,
} = actions;
export default reducer;
