import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: `${process.env.REACT_APP_ZOOMAPP_BACKEND_BASE_URL}/notification`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        const parsedToken = JSON.parse(token);
        headers.set("authorization", `Bearer ${parsedToken.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      getAllNotifications: builder.query({ query: () => "/" }),
      postNotification: builder.mutation({
        query: (body) => ({
          url: "/",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content.notifications,
      }),
      deleteNotification: builder.mutation({
        query: (body) => ({
          url: "/delete",
          method: "POST",
          body,
        }),
        transformResponse: (response) => response.content,
      }),
    };
  },
});

export const {
  useGetAllNotificationsQuery,
  usePostNotificationMutation,
  useDeleteNotificationMutation,
} = notificationsApi;
