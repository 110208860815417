import React, { useState, useMemo } from "react";
import { Box, Button, Icon } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { updateSelectedActivity } from "@store/activitySlice";
import {
  ActivityTypesCard,
  ActivityList,
  SingleActivity,
} from "@components/Activity";
import { RiAddCircleFill } from "react-icons/ri";

const views = {
  types: "types",
  list: "list",
  add: "add",
};

const Activity = ({ agenda }) => {
  const [view, setView] = useState(views.list);
  const [activityType, setActivityType] = useState("");
  const dispatch = useDispatch();

  const components = useMemo(
    () => ({
      [views.types]: (
        <ActivityTypesCard
          changeViewData={{ setView, views }}
          setActivityType={setActivityType}
        />
      ),
      [views.list]: (
        <ActivityList
          agenda={agenda}
          changeViewData={{ setView, views }}
          setActivityType={setActivityType}
        />
      ),
      [views.add]: (
        <SingleActivity
          agendaId={agenda?._id}
          changeViewData={{ setView, views }}
          activityType={activityType}
        />
      ),
    }),
    [agenda, activityType]
  );

  return (
    <Box
      borderRadius="12px"
      border="1px solid"
      borderColor="gray.200"
      my="2"
      p="3"
      shadow="sm"
    >
      <Button
        size="sm"
        variant="primary"
        rightIcon={<Icon as={RiAddCircleFill} fill="purple.200" />}
        isFullWidth={true}
        mb="3"
        disabled={!!agenda?.endTime}
        onClick={() => {
          dispatch(updateSelectedActivity({ activity: {} }));
          setView(views.types);
        }}
      >
        Add Activity
      </Button>
      {components[view]}
    </Box>
  );
};

export default Activity;
