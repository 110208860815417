import { connectSocket, disconnectSocket } from "@socket/utils";

const initiateSocket = (userId, meetingInstanceId) => {
  connectSocket(process.env.REACT_APP_ZOOMAPP_SOCKET_IO_URL, {
    auth: {
      userId,
      meetingInstanceId,
    },
  })
};

export * from "./listeners";
export * from "./emitters";
export { initiateSocket, disconnectSocket };
