import { useParams } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import {
  AgendaMeetingItem,
  ActivityView,
  EditAgenda,
} from "@components";
import { useMeeting, useProfile, useToastNotification } from "@hooks";
import { useUpdateSingleAgendaMutation } from "@store/api/agenda";
import { generateMeetingUuid } from "@utils/helpers";

const SingleAgenda = ({ agenda, setAgenda }) => {
  const { meetingObj } = useMeeting();
  const { showSuccessToast, showErrorToast } = useToastNotification();
  const { profile } = useProfile();
  const { agendaId } = useParams();
  const isAgendaDisabled = !!agenda?.live || !!agenda?.endTime;

  const [updateAgenda, { isLoading: updatingAgenda }] =
    useUpdateSingleAgendaMutation();
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure();

  const handleSaveForm = async (updatedAgendaDetails) => {
    const meetingUuid = generateMeetingUuid(
      updatedAgendaDetails.meetingId,
      profile._id
    );

    const { meetingId, ...rest } = updatedAgendaDetails;

    try {
      const response = await updateAgenda({
        agendaId,
        meetingData: { ...rest, meetingId: meetingObj ? meetingObj._id : meetingUuid },
      });

      setAgenda(response.data);
      showSuccessToast("Updated agenda successfully");
    } catch (error) {
      showErrorToast("Error updating agenda, please try again");
    } finally {
      onModalClose();
    }
  };

  const handleEdit = () => {
    onModalOpen();
  };

  return (
    <>
      <AgendaMeetingItem
        title={agenda?.title}
        onEdit={handleEdit}
        isDisabled={isAgendaDisabled}
      />
      <EditAgenda
        isOpen={isModalOpen}
        onClose={onModalClose}
        isLoading={updatingAgenda}
        agenda={agenda}
        handleSaveForm={handleSaveForm}
      />
      <ActivityView agenda={agenda} />
    </>
  );
};

export default SingleAgenda;
